import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { headers } from "../../../../App/Axios"
import { RoomType } from "../../../../App/enums"
import { roomDefaultPhoto } from "../../../../App/GlobaleVariables"
import HTTPService from "../../../../App/HTTPService"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import { CheckedBox, CloseIcon, UnCheckedBox } from "../../../Shared/Components/SvgIcons"
import UserCard from "../../../Shared/Components/UserCard"
import Button from "../../../Shared/Primitive/Button"
import Image from "../../../Shared/Primitive/Image"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { setRoomsData } from "../../../Shared/SharedSlices/RoomSlice"
import { onPostEventToRoom } from "./SharenEventToRoomServiceRequest"

function ShareEventToRoomModal() {
    const translation = useSelector(selectTranslations)
    const [checkedRoom, setCheckedRoom] = useState<number[]>([])
    const { id } = useParams()
    const dispatch = useDispatch()
    const ownedRoomsState = useSelector((state: RootState) => state.roomSlice.roomsSlice.owned_rooms)
    const eventId = useSelector((state: RootState) => state.pinboardIdSlice.id)
    const organizationRoomGetRequest = useCallback(() => {
        return HTTPService.get(`/organization/${id}/rooms/`, headers)
    }, [id])

    useEffect(() => {
        organizationRoomGetRequest().then(response => {
            if (response.status === 200) {
                const data = response.data
                dispatch(setRoomsData({ joined_rooms: data.joined_rooms, owned_rooms: data.owned_rooms }))
            }
        })
    }, [dispatch, organizationRoomGetRequest])

    const onRoomCheck = (id: number, index: number) => {
        if (checkedRoom.includes(id)) {
            setCheckedRoom(checkedRoom.filter((room, index) => room !== id))
        } else {
            setCheckedRoom(checkedRoom => [...checkedRoom, id])
        }
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, shareEventToRoomModal: false }))
    }

    const onEventShare = () => {
        dispatch<any>(onPostEventToRoom({ event_id: eventId!, rooms_id: checkedRoom }))
    }

    return (
        <div className="share-event-to-room-modal">
            <div className="share-event-to-room-modal__title-container">
                <div className="share-event-to-room-modal__title">{translation.shareToRoom}</div>
                <div onClick={onModalClose} className="share-event-to-room-modal__close-icon">
                    <CloseIcon />
                </div>
            </div>
            <div className="share-event-to-room-modal__content">
                {ownedRoomsState?.map((room, index) => {
                    return (
                        room.is_room_owner && (
                            <UserCard
                                key={room.id}
                                userCardStyle="room-card"
                                leftPanelStyle="left-panel"
                                middlePanelStyle="middle-panel"
                                rightPanelStyle="right-panel"
                                leftPanelItem={
                                    <Image
                                        src={room.photo || roomDefaultPhoto}
                                        className="image image__profile image__profile--size-small image__profile--fit"
                                    />
                                }
                                textStyle="text"
                                textStyleMore25="text"
                                textStyleContainer="text-container"
                                text={room.name}
                                subtext={room.type === RoomType.Public ? translation.public : translation.private}
                                subtextStyle={`${room.type === RoomType.Public ? "room-type-public" : "room-type-private"} `}
                                rightText={checkedRoom.includes(room.id) ? <CheckedBox /> : <UnCheckedBox />}
                                onRightItemClick={() => onRoomCheck(room.id, index)}
                            />
                        )
                    )
                })}
            </div>
            <div className="share-event-to-room-modal__button-container">
                <div className="share-event-to-room-modal__button">
                    <Button
                        onClick={onEventShare}
                        className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-blue-54 button--hover-brighter button--cursor-pointer button--border-radius-05"
                    >
                        {translation.share}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ShareEventToRoomModal
