import { useEffect, useRef } from "react"
import "../../../Styles/main.scss"

function AuthPageImage() {
    let videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>
    let coverRef = useRef() as React.MutableRefObject<HTMLImageElement>

    const videoPausePlayHandler = (e: Event) => {
        if (e.type == "playing") {
            videoRef.current.setAttribute("controls", "controls")
        } else if (e.type == "ended") {
            videoRef.current.classList.add("hidden")
            coverRef.current.classList.remove("hidden")
        }
    }

    const coverClickHandler = (e: Event) => {
        videoRef.current.classList.remove('hidden')
        videoRef.current.currentTime = 0
        videoRef.current.play()

        coverRef.current.classList.add('hidden')
    }

    useEffect(() => {
        videoRef.current.addEventListener("playing", videoPausePlayHandler, false)
        videoRef.current.addEventListener("pause", videoPausePlayHandler, false)
        videoRef.current.addEventListener("ended", videoPausePlayHandler, false)

        coverRef.current.addEventListener("click",coverClickHandler,false)
    })

    return (
        <>
        <img ref={coverRef} className="edusiia-cover" src="/edusiia-Video-Vorschau.png" />
        <div className="video-wrapper ">
            <video
                className="edusiia-video hidden"
                poster="/edusiia-Video-Vorschau.png"
                preload="auto"
                playsInline
                ref={videoRef}
            >
                <source src="edusiia-das-soziale-netzwerk.mp4" type="video/mp4" />
            </video>
            
        </div>
        </>
    )
}

export default AuthPageImage
