import { useSelector } from "react-redux"
import { RoleType } from "../../../App/enums"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import FeedCTASlider from "./FeedCTASlider"
import FeedItem from "./FeedItem"
import { LoadingAnimation } from "./SvgIcons"
import FeedEntities from "./FeedNewEntities"

function FeedContainer(props: { type: RoleType.USER | RoleType.ORGANIZATION; isLoading: boolean }) {
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)

    return (

        <>
            {props.isLoading && posts.length === 0 && (
                <>
                    <div className="feed-item__loading-card">
                        <LoadingAnimation />
                    </div>
                    <div className="feed-item__loading-card">
                        <LoadingAnimation />
                    </div>
                    <div className="feed-item__loading-card">
                        <LoadingAnimation />
                    </div>
                </>
            )}

            {posts?.map((post, index) => {
                if (index === 0) {
                    return (
                        <>
                            <FeedItem key={post.id} isLoading={false} type={props.type} post={post} />
                            <FeedCTASlider key={`cta-${post.id}`} />
                        </>
                    )
                }
                else if(index === 3){
                    return (
                        <>
                            <FeedEntities key={`dyk-${post.id}`} />
                            <FeedItem key={post.id} isLoading={false} type={props.type} post={post} />
                        </>
                    )
                }
                return <FeedItem key={post.id} isLoading={false} type={props.type} post={post} />
            })}
        </>
    )
}

export default FeedContainer
