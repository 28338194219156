import Picker from "emoji-picker-react"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Editor as TinyMCEEditor } from "tinymce"
import { headers } from "../../../App/Axios"
import { fileImageType, organizationProfileDefault, profileDefault } from "../../../App/GlobaleVariables"
import HTTPService from "../../../App/HTTPService"
import useOnScreen, { ConvertStringToHTML, documentNameLength, formatDate } from "../../../App/Helpers/Helpers"
import { hashtagRegex } from "../../../App/Regex"
import StorageService from "../../../App/StorageService"
import { CallerComponent, CommentType, EditorModifier, PhotoTypeModal, PostType, RoleType, StorageKey } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import { MentionedUsersType, RoomPosts } from "../../../Types/RoomPosts"
import { postSeenRequest } from "../../Room/RoomPost/RoomPostRequests"
import { setPostContentErrorState, setRoomPostPhoto, setRoomPostsData } from "../../Room/RoomPost/RoomPostSlice"
import Image from "../../Shared/Primitive/Image"
import useWindowSize from "../Hooks/useWindowSize"
import { setModalData } from "../SharedSlices/ModalSlice"
import CharacterCounter from "./CharacterCounter"
import EditorTextArea from "./Editor/EditorTextArea"
import { convertTagElement } from "./Editor/convertTagElement"
import FeedComments from "./FeedComments"
import HoverBox from "./HoverBox"
import MessageBody from "./MessageBody"
import Reaction from "./Reactions"
import { SendIcon, SmileIcon, SmileyFace } from "./SvgIcons"
import UserCard from "./UserCard"
import { HttpStatusCode } from "axios"

function FeedItem(props: { type: RoleType.USER | RoleType.ORGANIZATION; post: RoomPosts; isLoading: boolean }) {
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const [isEmojiSelectorOpen, setIsEmojiSelectorOpen] = useState(false)
    let emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const [isCommentEmojiSelectorOpen, setCommentIsEmojiSelectorOpen] = useState(false)
    let commentEmojiRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [isCommentSectionCollapsed, setIsCommentSectionCollapsed] = React.useState(true)
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const [inputState, setInputState] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const contentMaxLength = 2000
    const itemRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const menuRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const isVisible = useOnScreen(itemRef)
    const [seenRequestSent, SetSeenRequestSent] = useState(false)
    const [feedMenuOpen, setFeedMenuOpen] = useState(false)
    const fileName = props.post.post_file && props.post?.post_file!.split("/")!.pop()!.split("#")[0].split("?")[0]
    const fileType = fileName && fileName.slice(fileName.indexOf(".") + 1).toUpperCase()
    const screenWidth = useWindowSize()
    const breakpoint = 500

    const onRoomNameClick = (roomId: number, organizationId: number) => {
        switch (props.type) {
            case RoleType.USER:
                navigate(`/room/${roomId}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room/${organizationId}/${roomId}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const addRoomToBlacklistRequest = (roomId: number) => {
        return HTTPService.post(`/room/blacklist/`, { room: roomId }, headers)
    }

    const onIgnoreRoomContentClick = (roomId : number) => {
        addRoomToBlacklistRequest(roomId).then(response => {
            if(response.status === HttpStatusCode.Created){
                let filteredPosts = posts.filter(p => p.room_instance?.id !== roomId)
                dispatch(setRoomPostsData(filteredPosts))
            }
        })
        setFeedMenuOpen(false)
        return 
    }

    const onEventClick = () => {
        if (props.post.content_object?.id) {
            navigate(`/organization-profile/${props.post.content_object?.id!}/event/${props.post.event_id!}`)
            window.scrollTo(0, 0)
        }
        return
    }

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (feedMenuOpen && menuRef.current && !menuRef.current.contains(event.target as HTMLElement)) {
                setFeedMenuOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onFileClick = (e: (React.MouseEvent<HTMLAnchorElement, MouseEvent>)) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation();

        const accessToken = StorageService.getItem(StorageKey.accessToken) as string

        fetch(props.post.post_file!, {
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    let blob = res.blob().then(blob => {
                        let objectUrl = window.URL.createObjectURL(blob);
                        let anchor = document.createElement("a");

                        anchor.href = objectUrl;
                        anchor.download = props.post.post_file.split('media/')[1];
                        anchor.click();

                        window.URL.revokeObjectURL(objectUrl);
                    })
                }
            });
    }

    useEffect(() => {
        if (isVisible && !seenRequestSent) {
            //mark post as seen
            try {
                postSeenRequest(props.post.id, 'user', userDataState.id)
                SetSeenRequestSent(true)
            } catch {
                SetSeenRequestSent(false)
            }
        }
    }, [isVisible])

    const onRoomNameMouseOver = () => {
        //TODO
    }

    const onRoomNameMouseLeave = () => {
        //TODO
    }

    const onCommentClick = () => {
        isCommentSectionCollapsed ? setIsCommentSectionCollapsed(false) : setIsCommentSectionCollapsed(true)
    }

    const onNameClick = (id: number, type: string, organizationId: number) => {
        switch (type) {
            case RoleType.USER.toLowerCase():
                if (userDataState.id === id) {
                    navigate(`/user-profile`)
                    window.scrollTo(0, 0)
                } else {
                    navigate(`/user/${id}`)
                    window.scrollTo(0, 0)
                }
                break
            case RoleType.ORGANIZATION.toLowerCase():
                navigate(`/organization-profile/${id!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const openReactionsModal = (
        reaction_info: {
            count: number
            name: string
            users: { id: number; first_name?: string; last_name?: string; profile_photo?: { id: number; url_path: string } }[]
        }[]
    ) => {
        dispatch(setModalData({ open: true, reactionsModal: { status: true, reaction: reaction_info } }))
    }

    const onCommentEmojiClick = (emojiObject: any, event?: { preventDefault: () => void }) => {
        event!.preventDefault()
        if (editorRef.current) {
            editorRef.current!.insertContent(emojiObject.emoji)
        }
    }

    const onPhotoClick = () => {
        dispatch(setModalData({ open: true, viewPhotoTypeModal: PhotoTypeModal.POST_FILE }))
        dispatch(setRoomPostPhoto(props.post?.post_file!))
    }

    const postRequest = (data: FormData) => {
        return HTTPService.post(`/${PostType.comment}/`, data, headers)
    }

    const onPostSubmit = async () => {
        const objectId = userDataState.id
        const mentionedUsersArray: MentionedUsersType[] = []
        let modifiedContent = ""
        if (editorRef.current!) {
            const htmlContent = ConvertStringToHTML(editorRef!.current!.getContent({ format: "raw" }))
            const tagSpans = htmlContent.querySelectorAll("[data-type]")

            tagSpans.forEach((span, index) => {
                span.innerHTML = "@tagged"
                mentionedUsersArray.splice(index, 0, {
                    tagged_user: span.getAttribute("data-type") === RoleType.USER.toLocaleLowerCase() ? parseInt(span.id) : null,
                    tagged_org: span.getAttribute("data-type") === RoleType.ORGANIZATION.toLocaleLowerCase() ? parseInt(span.id) : null,
                })
                modifiedContent = htmlContent.outerHTML.replaceAll("<body>", "").replaceAll("</body>", "").replace(hashtagRegex, "$1")
            })

            if (mentionedUsersArray.length >= 1 || editorRef.current!.getContent({ format: "text" }).length > 0) {
                const formData = new FormData()

                formData.append(
                    "content",
                    modifiedContent.length > 0 ? modifiedContent : editorRef!.current!.getContent({ format: "raw" }).replace(hashtagRegex, "$1")
                )
                formData.append("content_type", role.toLowerCase())
                formData.append("object_id", objectId?.toString()!)
                formData.append("tags", JSON.stringify(mentionedUsersArray))

                formData.append("post", props.post.id.toString())

                try {
                    const response = await postRequest(formData)
                    if (response.status === 201) {

                        let updatedPosts = [...posts]
                        const idx = posts.findIndex(p => p.id === props.post.id)
                        updatedPosts[idx] = { ...posts[idx], post_comments: [...posts[idx].post_comments, response.data], total_commments: posts[idx].total_commments + 1 }
                        dispatch(setRoomPostsData(updatedPosts))
                        editorRef!.current.setContent("")
                    }
                } catch (error: any) {
                    if (error.response?.status === 400) {
                        dispatch(setPostContentErrorState(error.response.data.content[0]))
                    }
                    console.log(error)
                }
            }
        }
    }

    const onPostClick = (roomId: number, postId: number) => {
        setIsEmojiSelectorOpen(false)
        navigate(`/room-post/${roomId}/${postId}`)
        window.scrollTo(0, 0)
    }
    const mentionedUsers = document.querySelectorAll("[data-type]")
    convertTagElement(mentionedUsers, props.post.tags!, role, id!, userDataState.id)
    if (props.post.room_instance === undefined) {
        return <></>
    }
    return (
        <>
            <div className="feed-item" key={props.post.id} ref={itemRef}>
                {!props.post.room_joined && (
                    <div className="feed-item-suggested-content">{translation.maybeInteresing}</div>
                )}
                <div className="feed-item__card">
                    <div className="feed-item--padding-1">
                        <UserCard
                            imageSrc={
                                props.post?.content_type === RoleType.USER.toLowerCase()
                                    ? props.post.content_object?.profile_photo?.url_path || profileDefault
                                    : props.post.content_object?.photo?.url_path || organizationProfileDefault
                            }
                            text={
                                <div className="feed-item__post-info">
                                    <span onClick={
                                        props.post.content_object === null
                                            ? undefined
                                            : () => onNameClick(props.post.content_object?.id!, props.post.content_type, parseInt(id!))
                                    }>
                                        {props.post?.content_type === RoleType.ORGANIZATION.toLowerCase()
                                            ? props.post.content_object === null
                                                ? translation.deletedOrganization
                                                : props.post.content_object?.name
                                            : props.post.content_object === null
                                                ? translation.deletedUser
                                                : props.post.content_object?.first_name + " " + props.post.content_object?.last_name}</span>

                                    <br /><span onClick={() => onRoomNameClick(props.post.room_instance!.id, 0)} onMouseOver={onRoomNameMouseOver} onMouseLeave={onRoomNameMouseLeave} className="feed-item-room-card-small__text">{props.post.room_name}
                                        <HoverBox room={props.post.room_instance} />
                                    </span>

                                </div>
                            }
                            rightText={
                                <>
                                    <div className="feed-item-profile-card__time">{formatDate(props.post.date, "HH:mm ")!}</div>
                                    <div className="feed-item-profile-card__date">{formatDate(props.post.date, "dd MMM")!} </div>
                                    <div className="feed-item-profile-card__dots-menu feed-item-menu" ref={menuRef}   onClick={() => setFeedMenuOpen(!feedMenuOpen)}>
                                        <div className="feed-item-profile-card__dot" />
                                        <div className="feed-item-profile-card__dot" />
                                        <div className="feed-item-profile-card__dot" />
                                        {feedMenuOpen && (
                                            <div className="feed-item-menu-content" onClick={e => e.stopPropagation()}>
                                                <div className="feed-item-menu-item" onClick={() => onIgnoreRoomContentClick(props.post.room_instance?.id!)}>Inhalte dieses Raums ignorieren</div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            }
                            userCardStyle={"feed-item-profile-card"}
                            leftPanelStyle={`left-panel   ${props.post.content_object === null && "feed-item-profile-card__left-panel--deleted"}`}
                            middlePanelStyle={`middle-panel`}
                            rightPanelStyle={"right-panel"}
                            profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                            textStyle={`text ${props.post.content_object === null && "feed-item-profile-card__text--deleted"}`}
                            rightTextStyle={"right-text"}
                            onLeftItemClick={
                                props.post.content_object === null
                                    ? undefined
                                    : () => onNameClick(props.post.content_object?.id!, props.post.content_type, parseInt(id!))
                            }
                            textStyleMore25={`textMore25 ${props.post.content_object === null && "feed-item-profile-card__textMore25--deleted"}`}
                        />
                        
                        <div>
                            <div className="feed-item__text-container-no-border">
                                <div className="feed-item__text">
                                    {
                                        <MessageBody
                                            showPreview={false}
                                            text={props.post.content}
                                            caller={CallerComponent.feed}
                                            showRemovePreviewIcon={false}
                                            showCollapsed={true}
                                            hashtags={props.post.hashtags}
                                            isCollapsed={isCollapsed}
                                            toggleCollapse={() => setIsCollapsed(!isCollapsed)}
                                            onNavigationToExpandedPost={() => onPostClick(props.post.room, props.post.id)}
                                        />
                                    }
                                </div>

                                {props.post.event_photo && (
                                    <div className="room-post__event-image-container" onClick={onEventClick}>
                                        <Image className="room-post__event-image" src={props.post.event_photo} alt="event" />
                                    </div>
                                )}
                                {props.post.post_file ? (
                                    fileImageType.includes(props.post.file_type!) ? (
                                        <div className="room-post__post-image-container" onClick={onPhotoClick}>
                                            <Image className="room-post__post-image" src={props.post.post_file} alt="event" />
                                        </div>
                                    ) : (
                                        <div className="room-layout__document-container">
                                            <img className="room-layout__document-image" src={`/document-icon.svg`} alt="doc" />
                                            <div className="room-layout__document-content">
                                                <span className="room-layout__document-title" onClick={onFileClick}>
                                                    {documentNameLength(fileName, screenWidth, breakpoint)}</span>
                                                <p className="room-layout__document-size">{fileType}</p>
                                            </div>
                                        </div>
                                    )
                                ) : null}
                            </div>
                            <div className="feed-item__interaction-container">
                                <div className="feed-item__reactions-container">
                                    {props.post.reaction_info?.map((reaction, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`feed-item__reaction ${index === 2 && "feed-item__reaction--no-margin"}`}
                                                onClick={() => openReactionsModal(props.post.reaction_info!)}
                                            >
                                                <div className="feed-item__emoji" title={reaction.name}>
                                                    <Image src={`/reactions/${reaction.name}.svg`} className="feed-item__emoji-image" />
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {/* {totalReactionCount! > 0 && (
                                                                <div className={`feed-item__reaction-count `}>{props.post.reaction_info.c}</div>
                                                            )} */}

                                    {props.post.room_instance?.is_active && (
                                        <>
                                            <div
                                                onClick={() => {
                                                    setIsEmojiSelectorOpen(!isEmojiSelectorOpen)
                                                }}
                                                className="feed-item__add-emoji-icon"
                                            >
                                                <SmileyFace />
                                            </div>
                                            {isEmojiSelectorOpen && (
                                                <div ref={emojiRef} className="feed-item__emoji-picker">
                                                    <Reaction
                                                        caller={CallerComponent.post}
                                                        callerId={props.post.id}
                                                        postType="posts"
                                                        open={isEmojiSelectorOpen}
                                                        setIsEmojiSelectorOpen={setIsEmojiSelectorOpen}
                                                        isFeed={true}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className="feed-item-profile-card__comments" onClick={() => onCommentClick()}>
                                    {props.post.total_commments} {props.post.total_commments === 1 ? translation.comment : translation.comments}
                                </div>
                            </div>
                        </div>

                        <div className={`feed-item-profile-card__comment-section ${isCommentSectionCollapsed && "feed-item-profile-card__comment-section-hidden"}`}>
                            <p className="feed-item-profile-card__comment-title">Kommentare</p>
                            <FeedComments commentsarray={props.post.post_comments!} type={CommentType.post} />

                            <div className="room-layout__right-panel-input-container">
                                <div className="room-layout__photo-text-container">
                                    <EditorTextArea
                                        inputState={inputState}
                                        setInputState={setInputState}
                                        editorRef={editorRef}
                                        editorPlaceholder={translation.addAComment}
                                        setContent={setContent}
                                        editorHeight={62}
                                        modifier={EditorModifier.roomTextArea}
                                        initialEditorValue=""
                                        enterPressed={() => { }}
                                    />
                                </div>
                                <CharacterCounter max={contentMaxLength} current={content.length} modifier="feed" />
                                <div className="room-layout__right-panel-icons-container">
                                    <div onClick={() => setCommentIsEmojiSelectorOpen(!isCommentEmojiSelectorOpen)} className="room-layout__right-panel-icon ">
                                        <SmileIcon />
                                    </div>
                                    {isCommentEmojiSelectorOpen && (
                                        <div ref={commentEmojiRef} className="room-layout__emoji-picker">
                                            <Picker skinTonesDisabled={false} onEmojiClick={onCommentEmojiClick} />
                                        </div>
                                    )}
                                    {content.length > 0 && content.length < contentMaxLength + 1 ? (
                                        <div className="room-layout__right-panel-icon" onClick={onPostSubmit}>
                                            <SendIcon color="green" />
                                        </div>
                                    ) : (
                                        <div className="room-layout__right-panel-icon room-layout__right-panel-icon--disable">
                                            <SendIcon color="grey" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedItem
