import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { headers } from "../../../../App/Axios"
import { RoleType, RoomFilter, RoomType } from "../../../../App/enums"
import { organizationProfileDefault } from "../../../../App/GlobaleVariables"
import { capitalizeFirstLetter, formatDate } from "../../../../App/Helpers/Helpers"
import HTTPService from "../../../../App/HTTPService"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import { Room } from "../../../../Types/Room"
import ProfileRoomsItem from "../../../Shared/Components/ProfileRoomsItem"
import Button from "../../../Shared/Primitive/Button"
import UserProfileCard from "../UserProfileCard"
import { onUserRoomSearch } from "./UserProfileRoomServiceRequest"

function UserProfileRooms(props: {
    isExpandedScreen: boolean
    filterNumber: number
    hasSearch: boolean
    hasButton: boolean
    hasSearchCategories: boolean
    isYourProfile: boolean
    isEditable: boolean
}) {
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const searchedRoomsData = useSelector((state: RootState) => state.UserRoomSearchSlice.RoomsSlice.rooms)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [showButton, setShowButton] = useState<boolean>()
    const [hasNoData, setHasNoData] = useState<boolean>()
    const [roomData, setRoomData] = useState<Array<Room>>([])
    const { id, userId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let otherUserId = parseInt(userId!)
    const [inputState, setInputState] = useState("")
    const [selectedFilter, setSelectedFilter] = useState<string>(RoomFilter.room)

    const userRoomGetRequest = (id: number) => {
        return HTTPService.get(`/user/${id}/room/`, headers)
    }
    const sortedRoomsGetRequest = (id: number) => {
        return HTTPService.get(`/search-user-rooms/${id}/?sorting=room__name`, headers)
    }
    const sortedOrganizationsGetRequest = (id: number) => {
        return HTTPService.get(`/search-user-rooms/${id}/?sorting=organization__name`, headers)
    }
    useEffect(() => {
        if (userDataState.id || otherUserId) {
            selectedFilter === RoomFilter.room
                ? sortedRoomsGetRequest(props.isYourProfile ? userDataState.id! : otherUserId)
                      .then(response => {
                          if (response.status === 200) {
                              setRoomData(response.data.rooms)
                          }
                      })
                      .catch(error => {
                          if (error.response.status === 404) {
                              navigate("/not-found")
                          }
                      })
                : selectedFilter === RoomFilter.organization
                ? sortedOrganizationsGetRequest(props.isYourProfile ? userDataState.id! : otherUserId)
                      .then(response => {
                          if (response.status === 200) {
                              setRoomData(response.data.rooms)
                          }
                      })
                      .catch(error => {
                          if (error.response.status === 404) {
                              navigate("/not-found")
                          }
                      })
                : userRoomGetRequest(props.isYourProfile ? userDataState.id! : otherUserId)
                      .then(response => {
                          if (response.status === 200) {
                              setRoomData(response.data.room)
                          }
                      })
                      .catch(error => {
                          if (error.response.status === 404) {
                              navigate("/not-found")
                          }
                      })
        }
    }, [navigate, otherUserId, props.isYourProfile, userDataState, selectedFilter])

    const onNavigationToOtherUserRooms = () => {
        switch (role) {
            case RoleType.USER:
                const userPath = `/all-user-rooms/${userId}`
                navigate(userPath)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                const organizationPath = `/all-user-rooms/${id}/${userId}`
                navigate(organizationPath)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onNavigationToUserRooms = () => {
        let path = "/all-rooms"
        navigate(path)
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        if (roomData?.length === 0) {
            setHasNoData(true)
        } else {
            setHasNoData(false)
        }
        if (roomData!.length > 3) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [roomData])

    const onLastItem = (index: number) => {
        if (!props.isExpandedScreen && roomData.length < props.filterNumber + 1) {
            return roomData.length - 1 === index
        }
        if (!props.isExpandedScreen && roomData.length > props.filterNumber) {
            return index === props.filterNumber - 1
        }
        if (props.isExpandedScreen && searchedRoomsData.length === 0) {
            return roomData.length - 1 === index
        }
        if (props.isExpandedScreen && index < searchedRoomsData.length - 1) {
            return index === searchedRoomsData.length - 1
        } else {
            return false
        }
    }
    useEffect(() => {
        if (inputState.length > 0) {
            dispatch<any>(onUserRoomSearch(inputState, selectedFilter === RoomFilter.room, props.isYourProfile ? userDataState.id : parseInt(userId!)))
        }
    }, [dispatch, inputState, props.isYourProfile, selectedFilter, userDataState.id, userId])

    const onNavigationToSelectedRoom = (room: Room) => {
        switch (role) {
            case RoleType.USER:
                navigate(`/room/${room.id!}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room/${id}/${room.id!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    return (
        <div className={`user-profile-rooms ${props.isExpandedScreen && "user-profile-rooms--margin-top"}`}>
            {(props.isEditable || roomData.length > 0) && (
                <>
                    {" "}
                    <div className="user-profile-rooms__title-container">
                        <div className="user-profile-rooms__title">{translation.myRooms}</div>
                    </div>
                    <UserProfileCard
                        inputValue={inputState}
                        setInputValue={setInputState}
                        onFirstCategoryClick={() => {
                            setSelectedFilter(RoomFilter.room)
                        }}
                        onSecondCategoryClick={() => setSelectedFilter(RoomFilter.organization)}
                        onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputState(event.target.value)}
                        isFirstCategorySelected={selectedFilter === RoomFilter.room}
                        hasCategories={props.hasSearchCategories}
                        hasSearchField={props.hasSearch}
                        firstCategory={translation.room}
                        secondCategory={translation.organization}
                        children={
                            !hasNoData ? (
                                inputState.length > 0 && searchedRoomsData.length === 0 ? (
                                    <div className="user-profile-rooms__no-data-text">
                                        {`${translation.noResultThatContain} '${inputState}' ${translation.wasFound}`}
                                    </div>
                                ) : (
                                    (inputState.length === 0 ? roomData : searchedRoomsData)?.slice(0, props.filterNumber).map((item, index) => {
                                        return (
                                            <div className={`user-profile-rooms__item`} key={item.id}>
                                                <ProfileRoomsItem
                                                    onClick={() => onNavigationToSelectedRoom(item)}
                                                    isLastItem={onLastItem(index)}
                                                    image={item.organization?.photo?.url_path || organizationProfileDefault}
                                                    firstText={item.organization?.name!}
                                                    type={capitalizeFirstLetter(item.type === RoomType.Private ? translation.private : translation.public)}
                                                    secondText={item.name}
                                                    members={`${item.total_members}`}
                                                    date={`${translation.joined} ${formatDate(item?.date_joined!, "MMM yyyy")!}`}
                                                />
                                            </div>
                                        )
                                    })
                                )
                            ) : (
                                <div className="user-profile-rooms__empty-item">{translation.noRooms}</div>
                            )
                        }
                        button={
                            props.hasButton === true && showButton === true ? (
                                <div className="user-profile-rooms__button-container">
                                    <div className="user-profile-rooms__button-size">
                                        <Button
                                            onClick={props.isYourProfile ? onNavigationToUserRooms : onNavigationToOtherUserRooms}
                                            className="button button--border-radius-09 button--color-grey button--hover-darker button--cursor-pointer button--text-color-grey button--font-size-small button--font-weight-400  "
                                        >
                                            {translation.seeAllResults}
                                        </Button>
                                    </div>
                                </div>
                            ) : null
                        }
                    />
                </>
            )}
        </div>
    )
}

export default UserProfileRooms
