import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import IndividualProfileCard from "../../Shared/Components/IndividualProfileCard"
import MainLayout from "../../Shared/Layout/MainLayout"
import NewestMembersCard from "../../Shared/NewestMembers/NewestMemberCard"
import RoomDropdown from "../../Shared/Components/RoomDropdown"
import SponsorCard from "../../Shared/Components/SponsorCard"
import OrganizationDashboardFeed from "./OrganizationDashboardFeed"
import ModalGroup from "../../Shared/Components/ModalGroup"
import GoPremiumCard from "../../Shared/Components/GoPremiumCard"
import { RoleType, StorageKey, SubscriptionType } from "../../../App/enums"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import SessionService from "../../../App/SessionService"
import { RootState } from "../../../App/store"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import { SearchIcon } from "../../Shared/Components/SvgIcons"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"

function OrganizationDashboardScreen() {
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const [hasArticleCard] = useState(false)

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    useEffect(() => {
        SessionService.setItem(StorageKey.organizationId, id!)
        if(organization.tutorial_progress === 0){
            dispatch(setModalData({ open: true, showTutorial: true }))
        }
    }, [id,organization,dispatch])

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={<IndividualProfileCard />}
                leftPanelSecondComponent={<RoomDropdown type={RoleType.ORGANIZATION} />}
                leftPanelThirdComponent={
                    organization.subscription_type === SubscriptionType.free ? <GoPremiumCard shape="tall" text={translation.upgradeYourProfile} /> : null
                }
                RightPanelSecondComponent={translation.newsMembersText}
                RightPanelThirdComponent={<NewestMembersCard />}
                RightPanelForthComponent={
                    <div className="main-layout__sponsored-title">
                        {translation.sponsoredText}
                        <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                            <SearchIcon color="dark-blue" />
                        </div>
                    </div>
                }
                RightPanelFifthComponent={<SponsorCard />}
                overflowModifier={true}
                isDashboad={hasArticleCard}
            >
                <OrganizationDashboardFeed hasArticleCard={hasArticleCard} />
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default OrganizationDashboardScreen
