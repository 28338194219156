import { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { headers } from "../../../App/Axios"
import { OrganizationProfitType } from "../../../App/enums"
import { month, year } from "../../../App/GlobaleVariables"
import { capitalizeFirstLetter } from "../../../App/Helpers/Helpers"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { LoadingAnimation, PremiumIconSmall } from "../../Shared/Components/SvgIcons"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { onGetSubscriptionPlanLink } from "../GoPremium/GoPremiumServiceRequest"

function SubscriptionSettings() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const [packageInfo, setPackageInfo] = useState<{ recurring_fee: number; contract_period: { Quantity: number; Unit: string } }>()
    const [isLoading, setIsLoading] = useState(true)

    const getSubscriptionInformation = useCallback(() => {
        return HTTPService.get(`/org/${id}/current-subscription/`, headers)
    }, [id])

    useEffect(() => {
        setIsLoading(true)
        getSubscriptionInformation()
            .then(response => {
                if (response.status === 200) {
                    setPackageInfo({ recurring_fee: response.data.recurring_fee, contract_period: response.data.contract_period })
                    setIsLoading(false)
                }
            })
            .catch(error => console.error)
    }, [getSubscriptionInformation])

    const onGoPremiumScreenNavigation = () => {
        navigate(`/go-premium/${id}`)
        window.scrollTo(0, 0)
        dispatch(setModalData({ open: false, organizationSettings: false }))
    }

    const onSeePlanData = () => {
        dispatch<any>(onGetSubscriptionPlanLink(id!))
    }

    return (
        <div className="payment-settings">
            <div className="payment-settings__text-container">
                <p className="payment-settings__text">{capitalizeFirstLetter(translation.checkoutYourOrg)}</p>
                <p
                    onClick={onSeePlanData}
                    className={
                        organization.organization_type === OrganizationProfitType.NON_PROFIT
                            ? "payment-settings__gold-text  payment-settings__gold-text--text-decoration payment-settings__gold-text--margin-left-02 payment-settings__gold-text--text-decoration"
                            : "payment-settings__red-text payment-settings__red-text--margin-left-02  payment-settings__red-text--text-decoration"
                    }
                >
                    {translation.membership}
                </p>
            </div>

            <div
                className={`payment-settings__premium-container ${
                    organization.organization_type === OrganizationProfitType.PROFIT && "payment-settings__premium-container--color-red"
                }`}
            >
                {isLoading ? (
                    <div className="payment-settings__loading-container">
                        <LoadingAnimation />
                    </div>
                ) : (
                    <>
                        <div className="payment-settings__permium-container">
                            <div className="payment-settings__premium-title">
                                <div className="payment-settings__permium-icon">
                                    <PremiumIconSmall />
                                </div>
                                <div
                                    className={`payment-settings__premium-text payment-settings__premium-text--${
                                        organization.organization_type === OrganizationProfitType.NON_PROFIT ? "color-gold" : "color-red"
                                    } payment-settings__premium-text--font-size-18 payment-settings__premium-text--font-weight-700`}
                                >
                                    {translation.premium}
                                </div>
                            </div>
                        </div>
                        <div className="payment-settings__offer-container">
                            <div className="payment-settings__month-container ">
                                <div
                                    className={`payment-settings__premium-text payment-settings__premium-text${
                                        organization.organization_type === OrganizationProfitType.NON_PROFIT ? "--color-gold" : "--color-red"
                                    } payment-settings__premium-text--font-size-60 payment-settings__premium-text--font-weight-900 payment-settings__premium-text--margin-right-05`}
                                >
                                    {packageInfo?.contract_period.Unit === year ? 12 : packageInfo?.contract_period.Quantity}
                                </div>
                                <div
                                    className={`payment-settings__premium-text payment-settings__premium-text--${
                                        organization.organization_type === OrganizationProfitType.NON_PROFIT ? "color-gold" : "color-red"
                                    } payment-settings__premium-text--font-size-30 payment-settings__premium-text--font-weight-500`}
                                >
                                    {packageInfo?.contract_period.Unit === year ? translation.months : translation.month}
                                </div>
                            </div>
                            <div className="payment-settings__price-container">
                                <div className="payment-settings__premium-text payment-settings__premium-text--margin-top payment-settings__premium-text--color-blue payment-settings__premium-text--font-size-18 go-premium-price-card__text--font-weight-500 ">
                                    {packageInfo?.recurring_fee + "€ "}
                                    {packageInfo?.contract_period?.Unit! === month ? translation.perMonth : translation.perYear}{" "}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="payment-settings__text-container-center">
                <span className="payment-settings__text"> {translation.pricePage}</span>
                <span
                    className={
                        organization.organization_type === OrganizationProfitType.NON_PROFIT
                            ? "payment-settings__gold-text payment-settings__gold-text--margin-left-02 payment-settings__gold-text--text-decoration"
                            : "payment-settings__red-text payment-settings__red-text--margin-left-02  payment-settings__red-text--text-decoration"
                    }
                    onClick={onGoPremiumScreenNavigation}
                >
                    {translation.here}
                </span>
                <span className="payment-settings__text"> {translation.find}</span>
            </div>
        </div>
    )
}

export default SubscriptionSettings
