import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setUserData } from "../Module/Shared/SharedSlices/UserDataSlice"
import { hasAuthenticationToken } from "../Module/Authentication/SignIn/SigninSlice"
import Routing from "./Routing/Routing"
import StorageService from "./StorageService"
import { userNotificationGetRequest } from "../Module/Notifications/UserNotificationServiceRequest"
import { setUserNotifications } from "../Module/Notifications/NotificationSlice"
import { RootState } from "./store"
import { RoleType, StorageKey } from "./enums"
import { organizationNotificationGetRequest } from "../Module/Notifications/OrganizationNotificationServiceRequest"
import SessionService from "./SessionService"
import { chatListGetRequest } from "../Module/Chat/ChatServiceRequest"
import { setHasUnseenMessages } from "../Module/Chat/ChatSlice"
import { Conversation } from "../Types/Conversation"

function App() {
    const dispatch = useDispatch()
    const MINUTE_MS = 30000
    const organiztionId = SessionService.getItem(StorageKey.organizationId)
    const localUserData = StorageService.parse(StorageService.getItem("user") as string)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const isAuthenticated = useSelector((state: RootState) => state.signinSlice.authenticationSlice.hasAccessToken)
    dispatch<any>(hasAuthenticationToken({ hasAccessToken: StorageService.hasItem("accessToken") }))

    useEffect(() => {
        StorageService.hasItem("user")
            ? dispatch<any>(
                  setUserData({
                      id: localUserData.id,
                      email: localUserData.email,
                      location: localUserData.location,
                      location_state: localUserData.location_state,
                      cover_photo: localUserData.cover_photo,
                      first_name: localUserData.first_name,
                      last_name: localUserData.last_name,
                      description: localUserData.description,
                      edusiia_reasons: localUserData.edusiia_reasons,
                      profile_photo: localUserData.profile_photo,
                      privacy_settings: localUserData.privacy_settings,
                      hashtags: localUserData.hashtags,
                      online: localUserData.online,
                      tutorial_progress: localUserData.tutorial_progress
                  })
              )
            : dispatch<any>(
                  setUserData({
                      id: 0,
                      email: "",
                      location: "",
                      location_state: {id: 0, name_de: "", name_en: "", country: { 
                        id: 0,
                        name_de: "",
                        name_en: "",
                        abbreviation: "",
                        prefix: ""
                     }},
                      cover_photo: { id: 0, url_path: "" },
                      first_name: "",
                      last_name: "",
                      description: "",
                      edusiia_reasons: "",
                      profile_photo: { id: 0, url_path: "" },
                      hashtags: [],
                      online: false,
                      tutorial_progress: 0
                  })
              )
    }, [
        dispatch,
        localUserData?.cover_photo,
        localUserData?.description,
        localUserData?.edusiia_reasons,
        localUserData?.email,
        localUserData?.first_name,
        localUserData?.hashtags,
        localUserData?.id,
        localUserData?.last_name,
        localUserData?.location,
        localUserData?.location_state,
        localUserData?.privacy_settings,
        localUserData?.profile_photo,
    ])

    useEffect(() => {
        if (isAuthenticated && role === RoleType.USER) {
            chatListGetRequest().then(response => {
                if (response.status === 200) {
                    dispatch<any>(setHasUnseenMessages(response.data.results.filter((c:Conversation) => c.unseen_messages > 0).length > 0))
                }
            })


            const interval = setInterval(() => {
                userNotificationGetRequest().then(response => {
                    if (response.status === 200) {
                        dispatch<any>(setUserNotifications(response.data))
                    }
                })

                chatListGetRequest().then(response => {
                    if (response.status === 200) {
                        dispatch<any>(setHasUnseenMessages(response.data.results.filter((c:Conversation) => c.unseen_messages > 0).length > 0))
                    }
                })
            }, MINUTE_MS)
            return () => clearInterval(interval)
        }
        if (isAuthenticated && role === RoleType.ORGANIZATION) {
            const interval = setInterval(() => {
                organizationNotificationGetRequest(organiztionId!).then(response => {
                    if (response.status === 200) {
                        dispatch<any>(setUserNotifications(response.data))
                    }
                })
            }, MINUTE_MS)
            return () => clearInterval(interval)
        }
    }, [organiztionId, dispatch, isAuthenticated, role])

    return (
        <div className={`app`}>
            <Routing />
        </div>
    )
}

export default App