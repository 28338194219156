import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import "../../../Styles/main.scss"
import { PremiumIcon, PremiumIconSmall } from "./SvgIcons"
import TutorialBubble from "./TutorialBubble";
import { RootState } from "../../../App/store";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../App/i18n/i18nSlice";

function GoPremiumCard(props: { text: string; shape: "long" | "tall" }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const translation = useSelector(selectTranslations)

    const onGoPremiumScreenNavigation = () => {
        navigate(`/go-premium/${id}`)
        window.scrollTo(0, 0)
    }

    return (
        <>
            <div className={`go-premium-card-${props.shape}`}>
                <div onClick={onGoPremiumScreenNavigation} className={`go-premium-card-${props.shape}__content`}>
                    <div className={`go-premium-card-${props.shape}__icon`}>
                        <PremiumIcon />
                    </div>
                    <div className={`go-premium-card-${props.shape}__icon-small`}>
                        <PremiumIconSmall />
                    </div>
                    <div className={`go-premium-card-${props.shape}__text`}>{props.text}</div>
                </div>

                <TutorialBubble text={translation.tutorial_org_premium} modifier="roomlist" visible={organizationDataState.tutorial_progress === 5} hasNext={true} />
            </div>

            
        </>
    )
}

export default GoPremiumCard
