import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import "../../../../Styles/main.scss"
import Button from "../../../Shared/Primitive/Button"
import { AddBlueIcon, ExitIcon } from "../../../Shared/Components/SvgIcons"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import UserProfileCard from "../../../User/UserProfile/UserProfileCard"
import { EmployeeGetRequest, onEmployeeSearch } from "./OrganizationProfileEmployeeServiceRequests"
import { setEmployeesData, setRemoveEmployeeId } from "./EmployeesSlice"
import { RootState } from "../../../../App/store"
import { profileDefault } from "../../../../App/GlobaleVariables"
import { OrganizationEmployeeType } from "../../../../Types/OrganizationEmployeeType"
import { RoleType, UsersRoleInOrganization } from "../../../../App/enums"
import Image from "../../../Shared/Primitive/Image"

function OrganizationProfileEmployees(props: {
    isExpandedScreen: boolean
    filterNumber: number
    hasSearch: boolean
    isEditable: boolean
    hasButton: boolean
    filterTagNumber: number
    hasSearchCategories: boolean
    isYourOrganization: boolean
}) {
    const translation = useSelector(selectTranslations)
    const [showButton, setShowButton] = useState<boolean>()
    const [hasNoData, setHasNoData] = useState<boolean>()
    const EmployeesState = useSelector((state: RootState) => state.employeesSlice.employeesSlice.employees)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const SearchedEmployeesState = useSelector((state: RootState) => state.employeesSlice.searchEmployeesSlice.employees)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id, otherOrgId } = useParams()
    const organizationId = parseInt(id!)
    const [otherOrganizationEmployees, setOtherOrganizationEmployees] = useState<Array<OrganizationEmployeeType>>()
    const otherOrganizationId = parseInt(otherOrgId!)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const [inputState, setInputState] = useState("")

    const onRouteChange = () => {
        let path = `/organization-employees/${id}`
        navigate(path)
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        let isMounted = true
        if (props.isYourOrganization) {
            EmployeeGetRequest(organizationId).then(response => {
                if (isMounted && response.status === 200) {
                    dispatch(setEmployeesData(response.data.role))
                }
            })
        } else {
            if (otherOrganizationId) {
                EmployeeGetRequest(otherOrganizationId!).then(response => {
                    if (isMounted && response.status === 200) {
                        setOtherOrganizationEmployees(response.data.role)
                    }
                })
            } else {
                EmployeeGetRequest(organizationId!).then(response => {
                    if (isMounted && response.status === 200) {
                        setOtherOrganizationEmployees(response.data.role)
                    }
                })
            }
        }
        return () => {
            isMounted = false
        }
    }, [dispatch, organizationId, otherOrganizationId, props.isYourOrganization])

    useEffect(() => {
        if (EmployeesState?.length === 0) {
            setHasNoData(true)
        } else {
            setHasNoData(false)
        }
        if (EmployeesState?.length > 4) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [EmployeesState?.length])

    const onOrganizationEmployeesListLengthCheck = useCallback((employeesData: Array<OrganizationEmployeeType>) => {
        if (employeesData && employeesData.length === 0) {
            setHasNoData(true)
        } else {
            setHasNoData(false)
        }
        if (employeesData && employeesData.length > 4) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [])

    useEffect(() => {
        if (props.isYourOrganization) {
            onOrganizationEmployeesListLengthCheck(EmployeesState)
        } else {
            onOrganizationEmployeesListLengthCheck(otherOrganizationEmployees!)
        }
    }, [EmployeesState, otherOrganizationEmployees, props.isYourOrganization, onOrganizationEmployeesListLengthCheck])

    const onRemoveEmployeeModalOpen = (id: number) => {
        dispatch(setRemoveEmployeeId(id))
        dispatch(setModalData({ open: true, removeEmployeeModal: true }))
    }

    const onAddEmployeeModalOpen = () => {
        dispatch(setModalData({ open: true, addEmployeeModal: true }))
    }

    const onNavigationToUserProfile = (userId: number) => {
        switch (role) {
            case RoleType.USER:
                const userPath = `/user/${userId}`
                navigate(userPath)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                const organizationPath = `/user/${userId}/${id}`
                navigate(organizationPath)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onLastItem = (index: number) => {
        switch (props.isYourOrganization) {
            case true:
                if (!props.isExpandedScreen && EmployeesState.length < props.filterNumber + 1) {
                    return EmployeesState.length - 1 === index
                }
                if (!props.isExpandedScreen && EmployeesState.length > props.filterNumber) {
                    return index === props.filterNumber - 1
                }
                if (props.isExpandedScreen && inputState.length === 0) {
                    return EmployeesState.length - 1 === index
                }
                if (props.isExpandedScreen && inputState.length > 0) {
                    return SearchedEmployeesState.length - 1 === index
                }
                break
            case false:
                if (!props.isExpandedScreen && otherOrganizationEmployees?.length! < props.filterNumber + 1) {
                    return otherOrganizationEmployees?.length! - 1 === index
                }
                if (!props.isExpandedScreen && otherOrganizationEmployees?.length! > props.filterNumber) {
                    return index === props.filterNumber - 1
                }
                if (props.isExpandedScreen && inputState.length === 0) {
                    return otherOrganizationEmployees?.length! - 1 === index
                }
                if (props.isExpandedScreen && inputState.length > 0) {
                    return SearchedEmployeesState.length - 1 === index
                }
                break
            default:
                break
        }
    }

    useEffect(() => {
        inputState.length > 0 && dispatch<any>(onEmployeeSearch(inputState, props.isYourOrganization ? id! : otherOrgId!))
    }, [dispatch, id, inputState, otherOrgId, props.isYourOrganization, EmployeesState.length])

    return (
        <div className="organization-profile-employees">
            <div className="organization-profile-employees__title-container">
                <div className="organization-profile-employees__title">{translation.OurEmployees}</div>
                {props.isEditable && (
                    <div className="organization-profile-employees__icon" onClick={onAddEmployeeModalOpen}>
                        <AddBlueIcon />
                    </div>
                )}
            </div>
            <UserProfileCard
                inputValue={inputState}
                setInputValue={setInputState}
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputState(event.target.value)}
                hasCategories={props.hasSearchCategories}
                hasSearchField={props.hasSearch}
                children={
                    props.isYourOrganization ? (
                        !hasNoData ? (
                            (inputState.length > 0 ? SearchedEmployeesState : EmployeesState)?.slice(0, props.filterNumber).map((item, index) => {
                                return (
                                    <div
                                        key={item.id}
                                        className={`organization-profile-employees-item ${
                                            onLastItem(index) && "organization-profile-employees-item--border-none"
                                        } ${props.isExpandedScreen && "organization-profile-employees-item--width-96"}`}
                                    >
                                        <div
                                            onClick={() => onNavigationToUserProfile(item.user.id!)}
                                            className="organization-profile-employees-item__left-panel"
                                        >
                                            <Image
                                                src={item.user.profile_photo?.url_path || profileDefault}
                                                alt=""
                                                className="organization-profile-employees-item__image"/>
                                            <div className="organization-profile-employees-item__text">{item.user.first_name + " " + item.user.last_name}</div>
                                        </div>
                                        <div className="organization-profile-employees-item__right-panel">
                                            {item.id !== userDataState.id && item.name === UsersRoleInOrganization.employee && (
                                                <div
                                                    className="organization-profile-employees-item__right-panel-icon"
                                                    onClick={() => onRemoveEmployeeModalOpen(item.user.id)}
                                                >
                                                    <ExitIcon />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className="organization-profile-employees__empty-item"> {translation.YouHaventAddAnyEmployeeYet} </div>
                        )
                    ) : !hasNoData ? (
                        (inputState.length > 0 ? SearchedEmployeesState : otherOrganizationEmployees)?.slice(0, props.filterNumber).map((item, index) => {
                            return (
                                <div
                                    key={item.id}
                                    className={`organization-profile-employees-item ${onLastItem(index) && "organization-profile-employees-item--border-none"}`}
                                >
                                    <div onClick={() => onNavigationToUserProfile(item.user.id!)} className="organization-profile-employees-item__left-panel">
                                        <Image
                                            src={item.user.profile_photo?.url_path || profileDefault}
                                            alt=""
                                            className="organization-profile-employees-item__image"
                                        />
                                        <div className="organization-profile-employees-item__text">{item.user.first_name + " " + item.user.last_name}</div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className="organization-profile-employees__empty-item"> {translation.noEmployeesToBeShow} </div>
                    )
                }
                button={
                    props.hasButton === true && showButton === true ? (
                        <div className="user-profile-experience__button-container">
                            <div className="user-profile-experience__button-size">
                                <Button
                                    onClick={onRouteChange}
                                    className="button button--border-radius-09 button--color-grey button--hover-darker button--cursor-pointer button--text-color-grey button--font-size-small button--font-weight-400  "
                                >
                                    {translation.seeAllResults}
                                </Button>
                            </div>
                        </div>
                    ) : null
                }
            />
        </div>
    )
}

export default OrganizationProfileEmployees
