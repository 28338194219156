import HTTPService from "../../../App/HTTPService"
import StorageService from "../../../App/StorageService"
import { getSignupStatus, setEmailError } from "./SignupSlice"
import { StorageKey } from "../../../App/enums"

export const dropdownGetRequest = () => {
    return HTTPService.get("/referral/")
}

export const countryStateGetRequest = (lang : string) => {
    return HTTPService.get("/country-states?lang="+lang)
}

export const StateGetRequest = (id : number) => {
    return HTTPService.get("/state/" + id + "/")
}

export const orgCategoriesGetRequest = () => {
    return HTTPService.get("/organization-categories")
}

export const emailGetRequest =
    (userForm: { name: string; lastName: string; email: string; password: string; confirmPassword: string, location_state_id: number, involved_organisation_category_id: number }) => async (dispatch: Function) => {
        HTTPService.get(`/users/?email=${userForm.email}`, {
            email: userForm.email,
        }).then(async response => {
            if (response.data.length > 0) {
                await dispatch(setEmailError())
            } else {
                const signupForm = {
                    name: userForm.name,
                    lastName: userForm.lastName,
                    email: userForm.email,
                    password: userForm.password,
                    confirmPassword: userForm.confirmPassword,
                    location_state_id: userForm.location_state_id,
                    involved_organisation_category_id: userForm.involved_organisation_category_id,
                    // id: id,
                    privacy_settings: [
                        {
                            private_messages: true,
                            search_results: true,
                            show_location: true,
                            show_contact_info: true,
                        },
                    ],
                }

                StorageService.setItem(StorageKey.signupForm, JSON.stringify(signupForm))
                await dispatch(getSignupStatus({ status: true }))
            }
        })
    }
