import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RoleType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { Organization } from "../../../Types/Organization"
import { User } from "../../../Types/User"
import { newestMemberGetRequest } from "../NewestMembers/NewestMemberGetRequest"
import { newestOrganizationsGetRequest } from "../NewestOrganizations/NewestOrganizations"
import EntityContainer from "./EntityContainer"

function FeedNewEntitiesBox() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const [organizations, setOrganizations] = useState<Organization[]>([])
    const [user, setUser] = useState<User[]>([])
    const [entities,setEntities] = useState<JSX.Element[]>()

    const shuffle = (array: Array<User | Organization | JSX.Element>) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.slice(0, 5);
    };

    useEffect(() => {
        let isMounted = true

        newestMemberGetRequest()
            .then(response => {
                if (isMounted && response.status === 200) {
                    setUser(shuffle(response.data) as User[])
                }
            })
            .catch(error => {
                console.log(error)
            })

        newestOrganizationsGetRequest().then(response => {
            if (isMounted && response.status === 200) {
                setOrganizations(shuffle(response.data) as Organization[])
            }
        })

        return () => {
            isMounted = false
        }
    }, [dispatch])

    useEffect(() => {
        let entityList : JSX.Element[] = [];
        user.forEach(u => {
            let ec = <EntityContainer type={RoleType.USER} userObj={u} key={u.id} />
            entityList.push(ec)
        });
        organizations.forEach(o => {
            let ec = <EntityContainer type={RoleType.ORGANIZATION} orgObj={o} key={o.id} />
            entityList.push(ec)
        });

        setEntities(shuffle(entityList) as JSX.Element[])
    },[user,organizations])

    return (
        <>
            <div className="feed-item " key="feed-dyk">
                <div className="feed-item__card feed-item__dyk-box">
                    <div className="feed-item__title">
                        {translation.doYouKnow}
                    </div>

                    <div className="feed-item__entity-list">
                        {entities?.map(e => {
                            return e
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedNewEntitiesBox

export { FeedNewEntitiesBox }
