import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import Button from "../../Shared/Primitive/Button"
import Checkbox from "../../Shared/Primitive/Checkbox"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import ToggleSwitch from "../../Shared/Components/ToggleSwitch"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { createOrganization } from "./AddOrganizationServiceRequests"
import TextInput from "../../Shared/Primitive/TextInput"
import { InputType } from "../../../App/enums"
import { resetAddOrganizationError } from "../../Authentication/EdusiiaMatching/EdusiiaMatchingSlice"

function AddOrganizationModal() {
    const ErrorState = useSelector((state: RootState) => state.organizationsSlice.organizationErrorSlice.message)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [addOrganizationForm, setAddOrganizationForm] = useState({ name: "", email: "", about: "", website: "", isProfitable: false, representative: false })
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.type === InputType.checkbox ? event.target.checked : event.target.value
        setAddOrganizationForm({ ...addOrganizationForm, [event.target.name]: value })
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, addOrganization: false }))
        dispatch(resetAddOrganizationError())
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        if (addOrganizationForm.isProfitable && addOrganizationForm.representative) {
            dispatch<any>(createOrganization(userDataState, addOrganizationForm, 1, addOrganizationForm.representative, onModalClose))
        }
        if (!addOrganizationForm.isProfitable && addOrganizationForm.representative) {
            dispatch<any>(createOrganization(userDataState, addOrganizationForm, 2, addOrganizationForm.representative, onModalClose))
        }
    }

    return (
        <form onSubmit={onFormSubmit} className="add-organization-modal">
            <div className="add-organization-modal__title-container">
                <div className="add-organization-modal__title">{translation.createOrganization}</div>
                <div onClick={onModalClose} className="add-organization-modal__icon">
                    <CloseIcon />
                </div>
            </div>
            <div className="add-organization-modal__content">
                <div className="add-organization-modal__input-container">
                    <div className="add-organization-modal__text">{translation.organizationName}</div>
                    <TextInput
                        textinputsize={"medium-40"}
                        autoComplete="off"
                        name="name"
                        value={addOrganizationForm.name}
                        type={"text"}
                        placeholder={translation.name}
                        onChange={onInputChange}
                    />
                    <div className="add-organization-modal__error-text">{ErrorState.name}</div>
                </div>
                <div className="add-organization-modal__input-container">
                    <div className="add-organization-modal__text">{translation.organizationEmail}</div>
                    <TextInput
                        textinputsize={"medium-40"}
                        autoComplete="off"
                        name={"email"}
                        value={addOrganizationForm.email}
                        type={"text"}
                        placeholder={translation.Email}
                        onChange={onInputChange}
                    />
                    <div className="add-organization-modal__error-text">{ErrorState.email}</div>
                </div>
                <div className="add-organization-modal__input-container">
                    <div className="add-organization-modal__text">{translation.about}</div>
                    <textarea
                        autoComplete="off"
                        className="add-organization-modal__large-input"
                        name={"about"}
                        value={addOrganizationForm.about}
                        placeholder={translation.about}
                        rows={50}
                        cols={50}
                        maxLength={400}
                        onChange={onInputChange}
                    />
                    <div className="add-organization-modal__error-text">{ErrorState.description}</div>
                </div>
                <div className="add-organization-modal__input-container">
                    <div className="add-organization-modal__text">{translation.website}</div>
                    <TextInput
                        textinputsize={"medium-40"}
                        autoComplete="off"
                        name="website"
                        value={addOrganizationForm.website}
                        type={"text"}
                        placeholder="https://"
                        onChange={onInputChange}
                    />
                    <div className="add-organization-modal__error-text">{ErrorState.website_url}</div>
                </div>
                <div className="add-organization-modal__permissions-container">
                    <div className="add-organization-modal__text add-organization-modal__text--margin-left-none">{translation.permissions}</div>
                    <div className="add-organization-modal__checkbox-container">
                        <Checkbox color="dark-blue" name="representative" checked={addOrganizationForm.representative} onChange={onInputChange} />
                        <div className="add-organization-modal__checkbox-label"> {translation.isOrganizationRepresentative}</div>
                    </div>
                    <div className="add-organization-modal__error-text">{ErrorState.representative}</div>
                </div>
                <div className="add-organization-modal__switch-container">
                    <ToggleSwitch onChange={onInputChange} name="isProfitable" checked={addOrganizationForm.isProfitable} />
                    <div
                        className={`add-organization-modal__switch-text add-organization-modal__switch-text${
                            !addOrganizationForm.isProfitable && "--color-red"
                        }`}
                    >
                        {addOrganizationForm.isProfitable ? translation.forProfitOrganization : translation.nonProfitOrganization}
                    </div>
                </div>
            </div>
            <div className="add-organization-modal__button-container">
                <div className="add-organization-modal__button">
                    {addOrganizationForm.representative && (
                        <Button
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-gold button--hover-brighter button--cursor-pointer button--border-radius-05"
                        >
                            {translation.create}
                        </Button>
                    )}
                    {!addOrganizationForm.representative && (
                        <Button type="submit" className="button button--small button--color-grey button--text-color-grey button--border-radius-05">
                            {translation.create}
                        </Button>
                    )}
                </div>
            </div>
        </form>
    )
}

export default AddOrganizationModal
