import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "../../../Styles/main.scss"
import Button from "../../Shared/Primitive/Button"
import { LogoIcon } from "../../Shared/Components/SvgIcons"
import TextInput from "../../Shared/Primitive/TextInput"
import { forgotPasswordPostRequest } from "./ForgotPasswordServiceRequests"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../../App/store"
import { selectTranslations } from "../../../App/i18n/i18nSlice"

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState("")
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const errorState = useSelector((state: RootState) => state.forgotpasswordSlice.forgotPasswordError)
    const statusState = useSelector((state: RootState) => state.forgotpasswordSlice.forgotPasswordStatus)

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch<any>(forgotPasswordPostRequest(email))
    }

    useEffect(() => {
        if (statusState.status === true) {
            navigate("/emailconfirmation")
            window.scrollTo(0, 0)
        }
    }, [navigate, statusState])

    return (
        <form className="forgot-password-form" onSubmit={onFormSubmit}>
            <div className="forgot-password-form__logo">
                <LogoIcon />
            </div>
            <h2 className="h2 forgot-password-form__title">{translation.forgotPassword}?</h2>
            <div className="forgot-password-form__container">
                <p className="h4 forgot-password-form__text">{translation.pleaseEnterYourRegisteredEmailAddress}</p>
                <div className="forgot-password-form__input">
                    <p className="p3 forgot-password-form__text-email">{translation.email}</p>
                    <TextInput
                        textinputsize="medium"
                        type="text"
                        value={email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                        autoComplete="off"
                    />
                    <p className="p3  forgot-password-form__error-message">{errorState.message.error}</p>
                </div>
                <div className="forgot-password-form__button-container">
                    <div className=" forgot-password-form__button-size">
                        <Button
                            type="submit"
                            className="button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                        >
                            <div>{translation.reset}</div>
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ForgotPasswordForm
