import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { headers } from "../../../../App/Axios"
import { OrganizationRoomFilter, RoleType, RoomType, SubscriptionType } from "../../../../App/enums"
import { organizationProfileDefault, roomDefaultPhoto } from "../../../../App/GlobaleVariables"
import HTTPService from "../../../../App/HTTPService"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import { Room } from "../../../../Types/Room"
import Button from "../../../Shared/Primitive/Button"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import UserProfileCard from "../../../User/UserProfile/UserProfileCard"
import OrganizationProfileRoomItem from "./OrganizationProfileRoomItem"
import { onOrganizationRoomsSearch } from "./OrganizationProfileRoomServiceRequest"
import { capitalizeFirstLetter, formatDate } from "../../../../App/Helpers/Helpers"
import { RoomData } from "../../../../Types/RoomData"
import ProfileRoomsItem from "../../../Shared/Components/ProfileRoomsItem"

function OrganizationProfileOurRooms(props: {
    isExpandedScreen: boolean
    hasButton: boolean
    filterNumber: number
    hasSearch: boolean
    createRoom: boolean
    hasSearchCategories: boolean
    isYourOrganization: boolean
}) {
    const translation = useSelector(selectTranslations)
    const [showButton, setShowButton] = useState<boolean>()
    const [hasNoData, setHasNoData] = useState<boolean>()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id, otherOrgId } = useParams()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const searchedRooms = useSelector((state: RootState) => state.OrganizationSearchRoomsSlice)
    const [organizationRoomsData, setOrganizationRoomsData] = useState<RoomData>({ joined_rooms: [], owned_rooms: [] })
    const [inputState, setInputState] = useState("")
    const [selectedFilter, setSelectedFilter] = useState<string>(OrganizationRoomFilter.createdByUs)

    const searchedRoomsLength = searchedRooms.owned_rooms!.length + searchedRooms.joined_rooms!.length

    const organizationRoomGetRequest = useCallback((id: string) => {
        return HTTPService.get(`/organization/${id}/rooms/`, headers)
    }, [])

    useEffect(() => {
        let isMounted = true
        if (otherOrgId) {
            organizationRoomGetRequest(otherOrgId!).then(response => {
                if (isMounted && response.status === 200) {
                    setOrganizationRoomsData({
                        joined_rooms: response.data.joined_rooms,
                        owned_rooms: response.data.owned_rooms,
                    })
                }
            })
        } else {
            organizationRoomGetRequest(id!).then(response => {
                if (isMounted && response.status === 200) {
                    setOrganizationRoomsData({
                        joined_rooms: response.data.joined_rooms,
                        owned_rooms: response.data.owned_rooms,
                    })
                }
            })
        }
        return () => {
            isMounted = false
        }
    }, [id, organizationRoomGetRequest, otherOrgId, props.isYourOrganization])

    const onRouteChange = () => {
        let path = `/organization-rooms/${id}`
        navigate(path)
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        if (organizationRoomsData!.joined_rooms!.length + organizationRoomsData!.owned_rooms!.length === 0) {
            setHasNoData(true)
        } else {
            setHasNoData(false)
        }
        if (organizationRoomsData!.joined_rooms!.length + organizationRoomsData!.owned_rooms!.length > 3) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [organizationRoomsData])

    const onCreateRoom = () => {
        if (
            organizationDataState.subscription_type === SubscriptionType.free &&
            organizationRoomsData!.joined_rooms!.length + organizationRoomsData!.owned_rooms!.length > 0
        ) {
            dispatch(setModalData({ open: true, notPremiumCreateRoomModal: true }))
        } else {
            dispatch(setModalData({ open: true, createRoom: true }))
        }
    }

    const onLastItem = (index: number) => {
        if (!props.isExpandedScreen && organizationRoomsData!.joined_rooms!.length + organizationRoomsData!.owned_rooms!.length < props.filterNumber + 1) {
            return organizationRoomsData!.joined_rooms!.length + organizationRoomsData!.owned_rooms!.length - 1 === index
        }
        if (!props.isExpandedScreen && organizationRoomsData!.joined_rooms!.length + organizationRoomsData!.owned_rooms!.length > props.filterNumber) {
            return index === props.filterNumber - 1
        }
        if (props.isExpandedScreen && searchedRoomsLength === 0) {
            return organizationRoomsData!.joined_rooms!.length + organizationRoomsData!.owned_rooms!.length - 1 === index
        }
        if (props.isExpandedScreen && index < searchedRoomsLength - 1) {
            return searchedRoomsLength - 1 === index
        } else {
            return false
        }
    }

    useEffect(() => {
        inputState.length > 0 && dispatch<any>(onOrganizationRoomsSearch(inputState, props.isYourOrganization ? id! : otherOrgId!))
    }, [dispatch, id, inputState, otherOrgId, props.isYourOrganization])

    const onNavigationToSelectedRoom = (room: Room) => {
        switch (role) {
            case RoleType.USER:
                navigate(`/room/${room.id!}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room/${id}/${room.id!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    return (
        <div className="organization-profile-our-rooms">
            <div className="organization-profile-our-rooms__title-container">
                <div className="organization-profile-our-rooms__title">{translation.OurRooms}</div>
                {props.createRoom && (
                    <div className="organization-profile-our-rooms__button">
                        <Button
                            onClick={onCreateRoom}
                            className="button button--small button--radius-15 button--cursor-pointer button--hover-brighter button--color-gold button--text-color-white button--font-weight-700 button--font-size-small"
                        >
                            <p className="organization-profile-our-rooms__button-content">{translation.CreateRoom}</p>
                        </Button>
                    </div>
                )}
            </div>
            <UserProfileCard
                inputValue={inputState}
                setInputValue={setInputState}
                onInputChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputState(event.target.value)}
                hasSearchField={props.hasSearch}
                hasCategories={props.hasSearchCategories}
                firstCategory={translation.createdByUs}
                secondCategory={translation.memberIn}
                isFirstCategorySelected={selectedFilter === OrganizationRoomFilter.createdByUs}
                onFirstCategoryClick={() => {
                    setSelectedFilter(OrganizationRoomFilter.createdByUs)
                }}
                onSecondCategoryClick={() => setSelectedFilter(OrganizationRoomFilter.memberIn)}
                children={
                    !hasNoData ? (
                        inputState.length > 0 && searchedRoomsLength === 0 ? (
                            <div className="user-profile-rooms__no-data-text">
                                {`${translation.noResultThatContain} '${inputState}' ${translation.wasFound}`}
                            </div>
                        ) : (
                            (inputState.length > 0 && searchedRoomsLength > 0
                                ? selectedFilter === OrganizationRoomFilter.createdByUs
                                    ? searchedRooms.owned_rooms
                                    : searchedRooms.joined_rooms
                                : selectedFilter === OrganizationRoomFilter.createdByUs
                                ? organizationRoomsData.owned_rooms
                                : organizationRoomsData.joined_rooms
                            )
                                ?.slice(0, props.filterNumber)
                                .map((item, index) => {
                                    return (
                                        <div className={`organization-profile-our-rooms__item`} key={item.id}>
                                            {selectedFilter === OrganizationRoomFilter.createdByUs ? (
                                                <OrganizationProfileRoomItem
                                                    onClick={() => onNavigationToSelectedRoom(item)}
                                                    isLastItem={onLastItem(index)}
                                                    image={item.photo || roomDefaultPhoto}
                                                    firstText={item.name}
                                                    type={capitalizeFirstLetter(item.type === RoomType.Private ? translation.private : translation.public)}
                                                    members={`${item.total_members}`}
                                                    date={`${translation.opened} ${formatDate(item?.date_opened!, "MMM yyyy")!}`}
                                                />
                                            ) : (
                                                <ProfileRoomsItem
                                                    onClick={() => onNavigationToSelectedRoom(item)}
                                                    isLastItem={onLastItem(index)}
                                                    image={item!.organization?.photo?.url_path || organizationProfileDefault}
                                                    firstText={item!.organization?.name!}
                                                    type={capitalizeFirstLetter(item.type === RoomType.Private ? translation.private : translation.public)}
                                                    secondText={item.name}
                                                    members={`${item.total_members}`}
                                                    date={`${translation.joined} ${formatDate(item?.date_joined!, "MMM yyyy")!}`}
                                                />
                                            )}
                                            {inputState.length > 0 && searchedRoomsLength === 0 && (
                                                <div className="organization-profile-our-rooms__no-data-text">{`${translation.noResultThatContain} '${inputState}' ${translation.wasFound}`}</div>
                                            )}
                                        </div>
                                    )
                                })
                        )
                    ) : (
                        <div className="organization-profile-our-rooms__empty-item">{translation.YouHaventAddedARoomYet}</div>
                    )
                }
                button={
                    props.hasButton === true && showButton === true ? (
                        <div className="user-profile-experience__button-container">
                            <div className="user-profile-experience__button-size">
                                <Button
                                    onClick={onRouteChange}
                                    className="button button--border-radius-09 button--color-grey button--hover-darker button--cursor-pointer button--text-color-grey button--font-size-small button--font-weight-400  "
                                >
                                    {" "}
                                    <div className="user-profile-experience__button-content"> {translation.seeAllResults}</div>
                                </Button>
                            </div>
                        </div>
                    ) : null
                }
            />
        </div>
    )
}

export default OrganizationProfileOurRooms
