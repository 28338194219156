import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import { SmallHeartIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { DonationAmounts } from "./DonationAmountsArray"
import { onCustomAmountDonation } from "./DonationServiceRequest"

function Donation() {
    const translation = useSelector(selectTranslations)
    const [selectedAmount, setSelectedAmount] = useState<{ id: number; amount: string; packageId: string }>()
    const [customAmount, setCustomAmount] = useState("")
    const [packageId, setPakageId] = useState("")
    const userId = useSelector((state: RootState) => state.userDataSlice.id!)
    const dispatch = useDispatch()

    useEffect(() => {
        if (customAmount.length > 0) {
            setPakageId("1")
            setSelectedAmount({ id: 0, amount: "", packageId: "" })
        } else {
            setPakageId(selectedAmount?.packageId!)
        }
    }, [customAmount.length, selectedAmount?.packageId])

    const onDonate = () => {
        if (!customAmount) {
            window.open(`${process.env.REACT_APP_BILLWERK_LINK + packageId}?tag=user${userId}`, "_blank")
        } else {
            dispatch<any>(onCustomAmountDonation(customAmount!, userId!))
        }
    }

    const onBankTransferClick = () => {
        dispatch(setModalData({ open: true, donationBankTransfer: true }))
    }

    return (
        <div className="donation-card">
            <div className="donation-card__image-container">
                <div className="donation-card__image">
                    <img src="/edusiia_illustration_spende.png" alt="donate" />
                </div>
                <div className="donation-card__small-image">
                    <img src="/edusiia_illustration_spende.png" alt="donate" />
                </div>
            </div>
            <div className="donation-card__content">
                <div className="donation-card__title-container">
                    <div className="donation-card__title">
                        {translation.doYouLikeYour} <p>{translation.experienceInEdusiia}</p>
                    </div>
                    <div className="donation-card__title donation-card__title--font-weight-400">{translation.considerDonatingToOurCause}</div>
                </div>{" "}
                <div className="donation-bank-transfer__first-text-container">
                    <p className="donation-card__link-text donation-card__link-text--no-left-margin">{translation.bankTransferParagraph}</p>
                    <p className="donation-card__link-text donation-card__link-text--no-left-margin">{translation.wirDankenDirSEHR}</p>
                </div>
                <div className="donation-card__subtitle-container">
                    <div className="donation-card__subtitle">{translation.contributionAmount}</div>
                    <div className="donation-card__checkbox-conatiner">
                        <div className="donation-card__text">{translation.perMonth}</div>
                    </div>
                </div>
                <div className="donation-card__amounts-list-container">
                    {DonationAmounts.map(amountCard => {
                        const selectedItem = selectedAmount?.id === amountCard.id ? "selected" : ""
                        return (
                            <div key={amountCard.id} className="donation-card__amount-card-container">
                                <div
                                    onClick={() => {
                                        setSelectedAmount({ id: amountCard.id, amount: amountCard.amount, packageId: amountCard.packageId })
                                        setCustomAmount("")
                                    }}
                                    className={`donation-card__amount-card donation-card__amount-card--${selectedItem}`}
                                >
                                    €{amountCard.amount}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="donation-card__divider-container">
                    <div className="donation-card__divider" />
                </div>
                <div className="donation-card__subtitle-container">
                    <div className="donation-card__subtitle">{translation.otherAmount}</div>
                    <div className="donation-card__checkbox-conatiner">
                        <div className="donation-card__text">{translation.perMonth}</div>
                    </div>
                </div>
                <input
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCustomAmount(event.target.value)}
                    value={customAmount!}
                    className="donation-card__amount-input"
                    placeholder="00€"
                    type="number"
                />
                <div className="donation-card__link-text donation-card__link-text--display-none" onClick={onBankTransferClick}>
                    {" "}
                    {translation.donateOnceByBankTransfer}
                </div>
                <div className="donation-card__button-container">
                    <div className="donation-card__button">
                        <Button
                            onClick={onDonate}
                            className=" button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                        >
                            <div className="donation-card__button-content">
                                <SmallHeartIcon /> {translation.donate}
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Donation
