import React, { Key, useEffect, useState } from "react"
import "../../../Styles/main.scss"
import { VectorDown, VectorUp } from "../Components/SvgIcons"

interface EdusiiaItems {
    id: number
    name: string
}

function Dropdown(props: { placeholder: string; items: EdusiiaItems[]; itemId: null | number; setItemId: Function } & React.HTMLAttributes<HTMLDivElement>) {
    const [expanded, setExpanded] = useState(false)
    const [selected, setSelected] = useState("")
    const [isSelected, setIsSelected] = useState(false)

    const DropdownRef = React.useRef() as React.MutableRefObject<HTMLDivElement>

    const onDropdownOpen = () => {
        setExpanded(!expanded)
    }

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (expanded && DropdownRef.current && !DropdownRef.current.contains(event.target as HTMLElement)) {
                setExpanded(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onItemClick = (items: { id: Key; name: string }) => {
        const value = items.name
        setSelected(value)
        setIsSelected(true)
        props.setItemId(items.id)
        setExpanded(false)
    }

    return (
        <>
            <div ref={DropdownRef} className="dropdown" tabIndex={0}>
                <div className="dropdown__select" onClick={onDropdownOpen} data-testid="dropdown-select">
                <input
                    className="dropdown__select-input dropdown__default-cursor"
                    placeholder={ props.placeholder }
                    autoComplete="off"
                    value={isSelected ? selected : ''}
                    readOnly
                />
                    <div className="dropdown__icon">{expanded ? <VectorUp /> : <VectorDown />}</div>
                </div>
                {expanded && (
                    <div className="dropdown__container " data-testid="dropdown-container">
                        <div className="dropdown__content">
                            {props.items?.map((item: { id: number; name: string }) => (
                                <div
                                    className={selected === item.name ? "dropdown__item--selected-true" : "dropdown__item"}
                                    onClick={() => onItemClick(item)}
                                    key={item.id}
                                    data-testid={`dropdown-item${item.id}`}
                                >
                                    <p className="dropdown__text">{item.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
export default Dropdown
