import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Color, EmployeesNumber, OrganizationProfitType, Revenue } from "../../../App/enums"
import {
    FOR_PROFIT_ANNUALLY_500K_TO_1M,
    FOR_PROFIT_ANNUALLY_LESS_THAN_500K,
    FOR_PROFIT_ANNUALLY_MORE_THAN_1M,
    FOR_PROFIT_MONTHLY_500K_TO_1M,
    FOR_PROFIT_MONTHLY_LESS_THAN_500K,
    FOR_PROFIT_MONTHLY_MORE_THAN_1M,
    halloEmail,
    NON_PROFIT_ANNUALLY_ELEVEN_TO_ONEHUNDRED,
    NON_PROFIT_ANNUALLY_ONEHUNDRED_PLUS,
    NON_PROFIT_ANNUALLY_ONE_TO_TEN,
    NON_PROFIT_MONTHLY_ELEVEN_TO_ONEHUNDRED,
    NON_PROFIT_MONTHLY_ONEHUNDRED_PLUS,
    NON_PROFIT_MONTHLY_ONE_TO_TEN,
    organizationProfileDefault,
} from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { LoadingAnimation, PremiumIconLarge } from "../../Shared/Components/SvgIcons"
import Image from "../../Shared/Primitive/Image"
import RadioButton from "../../Shared/Primitive/RadioButton"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { GetOrganizationRequest } from "../OrganizationDashboard/GetOrganizationServiceRequest"
import GoPremiumDropdown from "./GoPremiumDropdown"
import GoPremiumPackageInfo from "./GoPremiumPackageInfo"
import GoPremiumPriceCard from "./GoPremiumPriceCard"
import { setPlanId } from "./UpgradePlanSlice"

function GoPremium() {
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const organizationState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const isProfitable = organizationState.organization_type === OrganizationProfitType.PROFIT
    const [employeeNumber, setEmployeeNumber] = useState(EmployeesNumber.ONE_TO_TEN)
    const [revenue, setRevenue] = useState(Revenue.LES_THAN_FIVEHUNDRED)
    const [packageInfo, setPackageInfo] = useState<{ month: number; year: number; monthlyId: string; annuallyId: string }>()
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const [isPremium, setIsPremium] = useState<boolean>()

    useEffect(() => {
        if (userDataState.id) {
            dispatch<any>(GetOrganizationRequest(id, userDataState, navigate, location))
        }
    }, [dispatch, id, location, navigate, userDataState, userDataState.id])

    useEffect(() => {
        setLoading(Object.keys(organizationState).length === 0)
    }, [dispatch, id, location, navigate, organizationState, userDataState, userDataState.id])

    useEffect(() => {
        setIsPremium(organizationState?.subscription_type! > 0)
    }, [organizationState?.subscription_type])

    useEffect(() => {
        if (isProfitable && revenue === Revenue.LES_THAN_FIVEHUNDRED) {
            setPackageInfo({ month: 59, year: 649, monthlyId: FOR_PROFIT_MONTHLY_LESS_THAN_500K, annuallyId: FOR_PROFIT_ANNUALLY_LESS_THAN_500K })
        }
        if (isProfitable && revenue === Revenue.FIVEHUNDRED_TO_MILION) {
            setPackageInfo({ month: 125, year: 1375, monthlyId: FOR_PROFIT_MONTHLY_500K_TO_1M, annuallyId: FOR_PROFIT_ANNUALLY_500K_TO_1M })
        }
        if (isProfitable && revenue === Revenue.MORE_THAN_MILION) {
            setPackageInfo({ month: 199, year: 2189, monthlyId: FOR_PROFIT_MONTHLY_MORE_THAN_1M, annuallyId: FOR_PROFIT_ANNUALLY_MORE_THAN_1M })
        }
        if (!isProfitable && employeeNumber === EmployeesNumber.ONE_TO_TEN) {
            setPackageInfo({ month: 29, year: 319, monthlyId: NON_PROFIT_MONTHLY_ONE_TO_TEN, annuallyId: NON_PROFIT_ANNUALLY_ONE_TO_TEN })
        }
        if (!isProfitable && employeeNumber === EmployeesNumber.ELEVEN_TO_ONEHUNDRED) {
            setPackageInfo({ month: 45, year: 495, monthlyId: NON_PROFIT_MONTHLY_ELEVEN_TO_ONEHUNDRED, annuallyId: NON_PROFIT_ANNUALLY_ELEVEN_TO_ONEHUNDRED })
        }
        if (!isProfitable && employeeNumber === EmployeesNumber.MORE_THAN_ONEHUNDRED) {
            setPackageInfo({ month: 59, year: 649, monthlyId: NON_PROFIT_MONTHLY_ONEHUNDRED_PLUS, annuallyId: NON_PROFIT_ANNUALLY_ONEHUNDRED_PLUS })
        }
    }, [employeeNumber, isProfitable, revenue])

    const onSubscriptionBuy = (isMonthly: boolean) => {
        if (isPremium) {
            dispatch(setPlanId(isMonthly ? packageInfo?.monthlyId! : packageInfo?.annuallyId!))
            dispatch(setModalData({ open: true, goPremiumUpgradeModal: true }))
        } else {
            window.open(`${process.env.REACT_APP_BILLWERK_LINK!}${+isMonthly ? packageInfo?.monthlyId : packageInfo?.annuallyId}?tag=org${id}`, "_blank")
        }
    }

    return (
        <div className="go-premium">
            {loading ? (
                <LoadingAnimation />
            ) : (
                <div className="go-premium__card">
                    <div className="go-premium__content">
                        <div className="go-premium__header">
                            <div className="go-premium__header-upper-panel">
                                <div className="go-premium__profile-image">
                                    <Image
                                        src={organizationState?.photo?.url_path || organizationProfileDefault}
                                        className="image image__profile image__profile--size-medium image__profile--fit"
                                    />
                                </div>

                                <div className="go-premium__header-upgrade-profile">
                                    <div className="go-premium__header-upgrade-profile-text-container">
                                        <div className="go-premium__text go-premium__text--font-size-18 go-premium__text--color-yellow go-premium__text--font-weight-700">
                                            {translation.upgradeYourProfile}
                                        </div>
                                        <div className="go-premium__text go-premium__text--font-size-18 go-premium__text--color-gold go-premium__text--font-weight-400">
                                            {translation.unleashYourFullPotential}
                                        </div>
                                    </div>
                                    <div className="go-premium__premium-icon">
                                        <PremiumIconLarge />
                                    </div>
                                </div>
                            </div>
                            {isPremium ? (
                                <div className="go-premium__header-middle-panel">
                                    <div className="go-premium__text-row go-premium__text go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-700">
                                        <span className="go-premium__text go-premium__text--margin-right-02 go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-500">
                                            {translation.hi}
                                        </span>
                                        {` ${organizationState.name ? organizationState.name : ""} ${translation.team},`}{" "}
                                        <span className="go-premium__text go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-500">
                                            {translation.checkOutYour}
                                        </span>
                                        <span className="go-premium__text go-premium__text--font-size-16 go-premium__text--color-gold go-premium__text--font-weight-500 ">
                                            {translation.currentSubscriptionPlan}.
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className="go-premium__header-middle-panel">
                                    <span className="go-premium__text-row go-premium__text go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-700">
                                        <span className="go-premium__text go-premium__text--margin-right-02 go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-500">
                                            {translation.hi}
                                        </span>
                                        {` ${organizationState.name ? organizationState.name : ""} ${translation.team}, `}
                                    </span>
                                    <span className="go-premium__text  go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-500">
                                        {translation.checkOutWhatOurUpgradedProfilesHaveToOfferYou}
                                    </span>
                                </div>
                            )}

                            <div className="go-premium__header-lower-panel">
                                <div className="go-premium__promo-card">
                                    <p className="go-premium__text go-premium__text--promo-text go-premium__text--color-blue go-premium__text--font-weight-500">
                                        {translation.threeMonthsForFree}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="go-premium__title-container">
                            <div
                                className={`go-premiumtext-container go-premium__text go-premium__text--font-size-18 go-premium__text--${
                                    !isProfitable ? "color-gold" : "color-red"
                                } go-premium__text--font-weight-700 `}
                            >
                                {!isProfitable ? translation.HowManyEmployeessYourOrganizationHave : translation.howMuchRevenuePerYear}
                            </div>
                            <div className="go-premium__divider"></div>
                        </div>
                        {!isProfitable && (
                            <div className="go-premium__for-profit-radio-container">
                                <div className="go-premium__radio-container">
                                    <div className="go-premium__radio">
                                        <RadioButton
                                            modifier={"radio__span--red"}
                                            name={"employee"}
                                            value={employeeNumber === EmployeesNumber.ONE_TO_TEN}
                                            onClick={() => setEmployeeNumber(EmployeesNumber.ONE_TO_TEN)}
                                        />
                                    </div>
                                    <div className="go-premiumtext-container go-premium__text go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-700 ">
                                        {translation.oneToTen}
                                    </div>
                                </div>
                                <div className="go-premium__radio-container">
                                    <div className="go-premium__radio">
                                        <RadioButton
                                            modifier={"radio__span--red"}
                                            name={"employee"}
                                            value={employeeNumber === EmployeesNumber.ELEVEN_TO_ONEHUNDRED}
                                            onClick={() => setEmployeeNumber(EmployeesNumber.ELEVEN_TO_ONEHUNDRED)}
                                        />
                                    </div>
                                    <div className="go-premiumtext-container go-premium__text go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-700 ">
                                        {translation.elevenToOneHundred}
                                    </div>
                                </div>
                                <div className="go-premium__radio-container">
                                    <div className="go-premium__radio">
                                        <RadioButton
                                            modifier={"radio__span--red"}
                                            name={"employee"}
                                            value={employeeNumber === EmployeesNumber.MORE_THAN_ONEHUNDRED}
                                            onClick={() => setEmployeeNumber(EmployeesNumber.MORE_THAN_ONEHUNDRED)}
                                        />
                                    </div>
                                    <div className="go-premiumtext-container go-premium__text go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-700 ">
                                        {translation.moreThanOneHundred}
                                    </div>
                                </div>
                            </div>
                        )}
                        {isProfitable && (
                            <div className="go-premium__for-profit-radio-container">
                                <div className="go-premium__radio-container">
                                    <div className="go-premium__radio">
                                        <RadioButton
                                            modifier={"radio__span--red"}
                                            name={"revenue"}
                                            value={revenue === Revenue.LES_THAN_FIVEHUNDRED}
                                            onClick={() => setRevenue(Revenue.LES_THAN_FIVEHUNDRED)}
                                        />
                                    </div>
                                    <div className="go-premiumtext-container go-premium__text go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-700 ">
                                        {"< 500K €"}
                                    </div>
                                </div>
                                <div className="go-premium__radio-container">
                                    <div className="go-premium__radio">
                                        <RadioButton
                                            modifier={"radio__span--red"}
                                            name={"revenue"}
                                            value={revenue === Revenue.FIVEHUNDRED_TO_MILION}
                                            onClick={() => setRevenue(Revenue.FIVEHUNDRED_TO_MILION)}
                                        />
                                    </div>
                                    <div className="go-premiumtext-container go-premium__text go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-700 ">
                                        {`500K - 1 ${translation.mln} €`}
                                    </div>
                                </div>
                                <div className="go-premium__radio-container">
                                    <div className="go-premium__radio">
                                        <RadioButton
                                            modifier={"radio__span--red"}
                                            name={"revenue"}
                                            value={revenue === Revenue.MORE_THAN_MILION}
                                            onClick={() => setRevenue(Revenue.MORE_THAN_MILION)}
                                        />
                                    </div>
                                    <div className="go-premiumtext-container go-premium__text go-premium__text--font-size-16 go-premium__text--color-blue go-premium__text--font-weight-700 ">
                                        {`> 1 ${translation.mln} €`}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="go-premium__cards-container">
                            <GoPremiumPriceCard
                                isCurrentPlan={packageInfo?.monthlyId === organizationState.current_plan}
                                onClick={() => onSubscriptionBuy(true)}
                                isProfitableOrganization={isProfitable}
                                monthNumber={1}
                                price={packageInfo?.month!}
                            />
                            <GoPremiumPriceCard
                                isCurrentPlan={packageInfo?.annuallyId === organizationState.current_plan}
                                onClick={() => onSubscriptionBuy(false)}
                                isProfitableOrganization={isProfitable}
                                monthNumber={12}
                                price={packageInfo?.year!}
                            />
                        </div>
                        <p className="go-premium__text go-premium__text--font-size-14 go-premium__text--color-red go-premium__text--margin">
                            {translation.VATnote}
                        </p>
                        <GoPremiumDropdown
                            hasMembersText={false}
                            color={Color.blue}
                            text={translation.custom}
                            firstItem={
                                <div className="go-premium__custom-card">
                                    <div className="go-premium__text go-premium__text--font-size-14 go-premium__text--color-blue go-premium__text--font-weight-500">
                                        {translation.contactTheEdisiiaTeam}
                                        <a
                                            href={`mailto:${halloEmail}`}
                                            className="go-premium__text go-premium__text--font-size-14 go-premium__text--color-blue-54 go-premium__text--font-weight-500 go-premium__text--decoration-none go-premium__text--margin-right-02"
                                        >
                                            {translation.edusiiaMail}
                                        </a>
                                        {translation.wellCreateAsuitablePackage}
                                    </div>
                                </div>
                            }
                        />
                        <div className="go-premium__info-text-container">
                            <div className="go-premium__text go-premium__text--font-size-14 go-premium__text--color-blue go-premium__text--font-weight-400">
                                {translation.forFurtherInformation}
                                <a
                                    className="go-premium__text go-premium__text--font-size-14 go-premium__text--color-blue-54 go-premium__text--font-weight-400 go-premium__text--decoration-none  go-premium__text--margin-right-02 go-premium__text--margin-left-02-web"
                                    href={"https://edusiia.com/datenschutz/"}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {translation.privacyPolicy}
                                </a>
                                {"&"}
                                <a
                                    className="go-premium__text go-premium__text--font-size-14 go-premium__text--color-blue-54 go-premium__text--font-weight-400 go-premium__text--decoration-none go-premium__text--margin-left-02"
                                    href={"https://edusiia.com/nutzungsbedingungen/"}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {translation.termsOfUse}.
                                </a>
                            </div>
                        </div>
                        <div className="go-premium__border-divider"></div>
                        <div className="go-premium__text go-premium__text--font-size-18 go-premium__text--color-gold go-premium__text--font-weight-700">
                            {translation.checkOutWhatYouGetByUpgrading}
                        </div>
                        <div className="go-premium__package-info-container">
                            <GoPremiumPackageInfo />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default GoPremium
