import { useSelector } from "react-redux"
import { roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { RoomType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { Room } from "../../../Types/Room"
import Image from "../../Shared/Primitive/Image"



function HoverBox(props: { room: Room }) {
    const translation = useSelector(selectTranslations)

    return (
        <div className="hoverbox">
            <div className="hoverbox__row">
                <div className="hoverbox__left-panel">
                    <div className="room-header__profile-photo">
                        <Image
                            src={props.room.photo || roomDefaultPhoto}
                            alt="room"
                            className="image__profile image__profile--size-small image__profile--fit"
                        />
                    </div>
                </div>
                <div className="hoverbox__right-panel">
                    <p className="hoverbox__room-name">{props.room.name}</p>
                    <p className="hoverbox__room-description truncated">{props.room.description.replace(/<[^>]*>/g, '')}</p>
                </div>
            </div>
            <div className="hoverbox__bottom-panel">
                <div className="hoverbox__row hoverbox__row__center">
                    <div
                        className={`rooms-dropdown__room-type ${props.room.type === RoomType.Private
                            ? "rooms-dropdown__room-type--background-color-gold"
                            : "rooms-dropdown__room-type--background-color-blue "
                            } hoverbox__room-type`}
                    >
                        {props.room.type === RoomType.Private ? translation.private : translation.public}
                    </div>
                    <div className="hoverbox__stat">{props.room.total_members} {props.room.total_members! < 2 ? translation.member : translation.members}</div>

                    <div className="hoverbox__stat">{props.room.total_posts} {props.room.total_posts! < 2 ? translation.post : translation.posts}</div>
                </div>
            </div>

        </div>
    )
}

export default HoverBox

