import { useNavigate } from "react-router-dom";
import { RoleType } from "../../../App/enums";
import { organizationProfileDefault, profileDefault } from "../../../App/GlobaleVariables";
import "../../../Styles/main.scss";
import { Organization } from "../../../Types/Organization";
import { User } from "../../../Types/User";
import Image from "../../Shared/Primitive/Image"


function EntityContainer(props: { userObj?: User; orgObj?: Organization; type: RoleType.USER | RoleType.ORGANIZATION }) {
    const navigate = useNavigate()
    const onEntityClick = (entityId: number, type: RoleType) => {
        switch (type) {
            case RoleType.USER:
                navigate("/user/" + entityId)
                break;
            case RoleType.ORGANIZATION:
                navigate("/organization-profile/" + entityId)
                break;
        }
    }

    return (
        <>
            {props.type === RoleType.USER && (
                <div className="entity-container" onClick={() => onEntityClick(props.userObj?.id!, RoleType.USER)}>
                    <div className="entity-container__panel-container">
                        <div className="entity-container__left-panel">
                            <div className="entity-container__profile-image-container">
                                <Image width='50px'
                                    className="entity-container__profile-image"
                                    src={props.userObj?.profile_photo?.url_path || profileDefault}
                                />
                            </div>
                        </div>
                        {(props.userObj?.description?.length! > 0 || props.userObj?.hashtags?.length! > 0) && (
                            <div className="entity-container__right-panel">
                                {props.userObj?.description?.length! > 0 && (
                                    <div className="entity-container__about-text truncated">
                                        {props.userObj?.description}
                                    </div>
                                )}
                                {props.userObj?.hashtags?.length! > 0 && (
                                    <div className="entity-container__hashtags">
                                        {props.userObj?.hashtags?.slice(0, 1).map((hashtag, index) => (
                                            <span className="profile-header__tag" key={index}>
                                                {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}


                    </div>
                    <div className="entity-container__profile-name">
                        {props.userObj?.first_name} {props.userObj?.last_name}
                    </div>
                </div>
            )}

            {props.type === RoleType.ORGANIZATION && (
                <div className="entity-container" onClick={() => onEntityClick(props.orgObj?.id!, RoleType.ORGANIZATION)}>
                    <div className="entity-container__panel-container">
                        <div className="entity-container__left-panel">
                            <div className="entity-container__profile-image-container">
                                <Image width='50px'
                                    className="entity-container__profile-image"
                                    src={props.orgObj?.photo?.url_path || organizationProfileDefault}
                                />
                            </div>
                        </div>
                        {(props.orgObj?.description?.length! > 0 || props.orgObj?.hashtags?.length! > 0) && (
                            <div className="entity-container__right-panel">
                                {props.orgObj?.description?.length! > 0 && (
                                    <div className="entity-container__about-text truncated">
                                        {props.orgObj?.description}
                                    </div>
                                )}
                                {props.orgObj?.hashtags?.length! > 0 && (
                                    <div className="entity-container__hashtags">
                                        {props.orgObj?.hashtags?.slice(0, 1).map((hashtag, index) => (
                                            <span className="profile-header__tag" key={index}>
                                                {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="entity-container__profile-name">
                        {props.orgObj?.name}
                    </div>
                </div>
            )}
        </>
    )
}

export default EntityContainer
