import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { RoleType } from "../../App/enums"
import { not_found } from "../../App/GlobaleVariables"
import { capitalizeFirstLetter, formatDate } from "../../App/Helpers/Helpers"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { RootState } from "../../App/store"
import { getOrganizationData } from "../Organization/OrganizationDashboard/GetOrganizationServiceRequest"
import BackgroundWrapper from "../Shared/Components/BackgroundWrapper"
import DonateButton from "../Shared/Components/DonateButton"
import IndividualProfileCard from "../Shared/Components/IndividualProfileCard"
import ModalGroup from "../Shared/Components/ModalGroup"
import OrganizationProfileCard from "../Shared/Components/OrganizationProfileCard"
import RoomDropdown from "../Shared/Components/RoomDropdown"
import SponsorCard from "../Shared/Components/SponsorCard"
import Header from "../Shared/Header/Header"
import Footer from "../Shared/Layout/Footer"
import MainLayout from "../Shared/Layout/MainLayout"
import NewestMembersCard from "../Shared/NewestMembers/NewestMemberCard"
import NewestOrganizationsCard from "../Shared/NewestOrganizations/NewestOrganizations"
import UserNotificationDropdownItem from "./NotificationDropdownItem"
import { onMarkAllOrganizationNotificationsAsRead, onMarkSingleOrganizationNotificationsAsRead } from "./OrganizationNotificationServiceRequest"
import { onMarkAllUserNotificationsAsRead, onMarkSingleUserNotificationsAsRead } from "./UserNotificationServiceRequest"
import { SearchIcon } from "../Shared/Components/SvgIcons"

function NotificationsScreen() {
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const notifications = useSelector((state: RootState) => state.UserNotificationSlice.notifications)
    const [hasNotification, setHasNotification] = useState<boolean>()
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (notifications.length === 0) {
            setHasNotification(false)
        } else {
            setHasNotification(true)
        }
        if (id) {
            getOrganizationData(id!)
                .then(response => {
                    if (!response.data.is_admin) {
                        navigate("/no-permission")
                    }
                })
                .catch()
        }
    }, [notifications.length])

    const onMarkAsRead = () => {
        if (role === RoleType.USER) {
            dispatch<any>(onMarkAllUserNotificationsAsRead())
        } else {
            dispatch<any>(onMarkAllOrganizationNotificationsAsRead(id!))
        }
    }

    const onNotificationClick = (notificationId: number, path: string) => {
        navigate(`/${path}`)

        switch (role) {
            case RoleType.USER:
                dispatch<any>(onMarkSingleUserNotificationsAsRead(notificationId))
                break
            case RoleType.ORGANIZATION:
                dispatch<any>(onMarkSingleOrganizationNotificationsAsRead(notificationId, id!))
                break
            default:
                break
        }
    }

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={role === RoleType.USER ? <OrganizationProfileCard /> : <IndividualProfileCard />}
                leftPanelSecondComponent={<RoomDropdown type={RoleType.USER} />}
                leftPanelForthComponent={role === RoleType.USER && translation.newestOrganizations}
                leftPanelFifthComponent={role === RoleType.USER ? <NewestOrganizationsCard /> : null}
                RightPanelFirstComponent={donationLink === not_found ? <DonateButton /> : null}
                RightPanelSecondComponent={translation.newsMembersText}
                RightPanelThirdComponent={<NewestMembersCard />}
                RightPanelForthComponent={
                    <div className="main-layout__sponsored-title">
                        {translation.sponsoredText}
                        <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                            <SearchIcon color="dark-blue" />
                        </div>
                    </div>
                }
                RightPanelFifthComponent={<SponsorCard />}
                overflowModifier={true}
            >
                <div className="notifications-screen">
                    <div className="notifications-screen__title-container">
                        <div className="notifications-screen__title">{translation.notifications}</div>
                        <div onClick={onMarkAsRead} className="notifications-screen__button">
                            {translation.markAllRead}
                        </div>
                    </div>
                    <div className="notifications-screen__card">
                        <div className="notifications-screen__content">
                            {hasNotification ? (
                                notifications?.map((notification, index) => {
                                    return (
                                        <UserNotificationDropdownItem
                                            isLastItem={notifications.length - 1 === index}
                                            key={notification.id}
                                            id={notification.id}
                                            sender={notification!.content_type!}
                                            photoSrc={notification.content_object?.photo?.url_path || notification.content_object?.profile_photo?.url_path}
                                            itemSizeLarge={true}
                                            first_name={notification.content_object?.first_name || translation.deletedUser}
                                            last_name={notification.content_object?.last_name}
                                            name={notification.content_object?.name || translation.deletedOrganization}
                                            notification={capitalizeFirstLetter(notification.content)}
                                            date={ formatDate(notification?.created_at!, "HH:mm dd.MM.yyyy")}
                                            isRead={notification.read}
                                            post={notification.post}
                                            onClick={() => onNotificationClick(notification.id!, notification.redirect_url)}
                                            roomId={notification.room}
                                            redirect_url={notification.redirect_url}
                                            action_url={notification?.action_url!}
                                            invitation_id={notification.invitation_id}
                                            isNotificationScreen={true}
                                        />
                                    )
                                })
                            ) : (
                                <div className="notifications-screen__text">{translation.youHaveNoNotifications}</div>
                            )}
                        </div>
                    </div>
                </div>
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default NotificationsScreen
