import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { OrganizationProfitType, PasswordConfirmationEnum } from "../../../App/enums"
import { eventTitleMaxLength } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import HashtagInput from "../../Shared/Components/HashtagInput"
import SingleTextInput from "../../Shared/Components/SingleTextInput"
import { TooltipQuestionmark } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import TextInput from "../../Shared/Primitive/TextInput"
import Tooltip from "../../Shared/Primitive/Tooltip"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { patchOrganizationAccount } from "./OrganizationAccountServiceRequest"
import { AccountSettings, EventType } from "./OrganizationSettingsInterfaces"
import useWindowSize from "../../Shared/Hooks/useWindowSize"
import SearchableDropdown from "../../Shared/Components/SearchableDropdown"

function OrganizationAccountSettings() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const userId = useSelector((state: RootState) => state.userDataSlice.id)
    const isOwner = useSelector((state: RootState) => state.organizationsSlice.isOwnerSlice.isOwner)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const errorMessages = useSelector((state: RootState) => state.organizationsSlice.organizationAccountErrorSlice.message)
    const [hashtags, setHashtags] = useState<string[]>(organization.hashtags! || [])
    const translation = useSelector(selectTranslations)
    const [orgStateFieldError, setOrgStateFieldError] = useState("")
    const [accountSettings, setAccountSettings] = useState<AccountSettings>({
        name: organization.name,
        website_url: organization.website_url || "",
        email: organization.email!,
        location: organization.location || "",
        location_state: organization.location_state,
        location_state_id: organization.location_state != null? organization.location_state.id : 0,
        organization_type: organization.organization_type!,
    })
    const organizationType = "organization_type"
    const screenWidth = useWindowSize()
    const breakpoint = 768

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & EventType) => {
        const value = event.type === organizationType ? event.value : event.target.value
        const target = event.type === organizationType ? organizationType : event.target.name
        setAccountSettings({ ...accountSettings, [target]: value })
    }

    const setLocationState = (newState : number) => {
        const value = {id: newState, name_de: "", name_en: "", country: { 
            id: 0,
            name_de: "",
            name_en: "",
            abbreviation: "",
            prefix: ""
         }}
        const target = 'location_state'
        setAccountSettings({ ...accountSettings, [target]: value })
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()

        if (accountSettings.email !== organization.email) {
            dispatch(
                setModalData({
                    open: true,
                    userConfirmPasswordModalState: { status: true, setData: accountSettings, type: PasswordConfirmationEnum.organization_email },
                })
            )
        } else {
            const data = {
                name: accountSettings.name,
                website_url: accountSettings.website_url,
                email: accountSettings.email,
                location: accountSettings.location,
                location_state_id: accountSettings.location_state != null? accountSettings.location_state.id: 0,
                organization_type: accountSettings.organization_type!,
                hashtag_list: hashtags,
            }
            dispatch<any>(patchOrganizationAccount(id!, userId!, data))
        }
    }

    return (
        <div className="organization-accout-settings">
            <div className="organization-accout-settings__content">
                <SingleTextInput
                    title={translation.organizationName}
                    textInput={
                        <TextInput
                            type="text"
                            textinputsize="large-40"
                            placeholder={translation.organizationName}
                            name="name"
                            value={accountSettings.name}
                            onChange={onInputChange}
                            autoComplete="off"
                            maxLength={50}
                        />
                    }
                    current={accountSettings.name.length}
                    max={eventTitleMaxLength}
                    errorText={errorMessages.name}
                />
                <SingleTextInput
                    title={translation.hashtags}
                    hasTooltip={true}
                    textInput={<HashtagInput hashtags={hashtags} setHashtags={setHashtags} />}
                    errorText={""}
                    tooltipContent={
                        <p>
                            {translation.hashtagTooltip}
                            <br />
                            {translation.hashtagTooltip2}
                            <br />
                            {translation.hashtagTooltip3}
                        </p>
                    }
                />
                <SingleTextInput
                    title={translation.email}
                    textInput={
                        <TextInput
                            type="email"
                            textinputsize="large-40"
                            placeholder={translation.email}
                            name="email"
                            value={accountSettings.email! ?? ""}
                            onChange={onInputChange}
                            autoComplete="off"
                            disabled={!isOwner}
                            isdisabled={isOwner ? false : true}
                        />
                    }
                    errorText={errorMessages.email}
                />
                <SingleTextInput
                    title={translation.website}
                    textInput={
                        <TextInput
                            type="text"
                            textinputsize="large-40"
                            placeholder={translation.website}
                            name="website_url"
                            value={accountSettings.website_url!}
                            onChange={onInputChange}
                            autoComplete="off"
                        />
                    }
                    errorText={errorMessages.website_url}
                />
                
                <SearchableDropdown inSettingsModal={true} hasTooltip={true}
                    tooltipContent={
                        <p>
                            {translation.locationTooltip} <br /> {translation.locationTooltip2} <br /> {translation.locationTooltip3}
                            <br /> {translation.locationTooltip4}
                        </p>
                    } title={translation.location} validationError={orgStateFieldError} itemId={accountSettings.location_state?.id} setItemId={setLocationState}/>

                <p className="single-text-input__text">{translation.organizationRegisteredAs}</p>

                <div
                    className={`add-organization-modal__switch-text add-organization-modal__switch-text--padding add-organization-modal__switch-text${
                        organization.organization_type === OrganizationProfitType.NON_PROFIT && "--color-red"
                    }`}
                >
                    {organization.organization_type === OrganizationProfitType.NON_PROFIT ? translation.nonProfit : translation.forProfit}
                    <Tooltip
                        direction="right"
                        content={
                            <p>
                                {translation.ifYouWantToChangeThisInfo} <br></br> {translation.pleaseContactUsVia}
                                <br></br> {translation.changeInfoMain}
                            </p>
                        }
                    >
                        <TooltipQuestionmark />
                    </Tooltip>
                </div>
            </div>
            <div className="organization-accout-settings__button-container">
                <div className="organization-accout-settings__button">
                    <Button
                        onClick={onFormSubmit}
                        className={`button ${
                            screenWidth > breakpoint && "button--border-radius-05"
                        } button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 `}
                    >
                        {translation.save}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default OrganizationAccountSettings
