import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import Button from "../../../Shared/Primitive/Button"
import { CloseIcon, Upload } from "../../../Shared/Components/SvgIcons"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import Image from "../../../Shared/Primitive/Image"
import { deleteEventRequest, patchEventRequest } from "./OrganizationPinboardServiceRequest"
import { GetOrganizationRequest } from "../../OrganizationDashboard/GetOrganizationServiceRequest"
import { useParams } from "react-router-dom"
import { RootState } from "../../../../App/store"
import { eventDescriptionMaxLength, eventTitleMaxLength, organizationProfileDefault } from "../../../../App/GlobaleVariables"
import { isDefined } from "../../../../App/Helpers/Helpers"
import { getPhotoSizeErrorMessage } from "../../../Shared/SharedSlices/RoomSlice"
import CharacterCounter from "../../../Shared/Components/CharacterCounter"
import EditorTextArea from "../../../Shared/Components/Editor/EditorTextArea"
import { Editor as TinyMCEEditor } from "tinymce"
import { EditorModifier } from "../../../../App/enums"
import { htmlRegex } from "../../../../App/Regex"
import { getEventDescriptionErrorState } from "../../../Shared/SharedSlices/SponsorCardSlice"

function OrganizationProfileEditEvent() {
    const { id } = useParams()
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const photoziseErrorState = useSelector((state: RootState) => state.roomSlice.photoSizeErrorSlice.message)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const eventId = useSelector((state: RootState) => state.sponsorSlice.EventIdSlice.id)
    const organizationId: number = organization.id!
    const events = useSelector((state: RootState) => state.sponsorSlice.SponsorCardSlice.events)
    const selectedEvent = events.find(event => event.id === eventId)
    const [title, setTitle] = useState(selectedEvent!.title)
    const [description, setDescription] = useState(selectedEvent!.description)
    const [image, setImage] = useState<any>(selectedEvent!.event_photo || null)
    const [previewUrl, setPreviewUrl] = useState<string>()
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const textWithoutHtml = description!.replaceAll(htmlRegex, "")
    const descriptionErrorState = useSelector((state: RootState) => state.sponsorSlice.EventDescriptionErrorSlice.message)

    useEffect(() => {
        GetOrganizationRequest(id, userDataState)
        dispatch(getEventDescriptionErrorState(""))
    }, [dispatch, id, userDataState, userDataState.id])

    const onCloseModal = () => {
        dispatch(setModalData({ open: false, editEvent: false }))
        dispatch(getPhotoSizeErrorMessage(false))
        setTitle("")
        setDescription("")
        setImage(null)
        dispatch(getEventDescriptionErrorState(""))
    }

    const onEventDelete = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch<any>(deleteEventRequest(organizationId, eventId))
    }

    const onPhotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (files) {
            const reader = new FileReader()
            reader.readAsDataURL(files[0])
            reader.addEventListener("load", () => {
                setImage(files[0])
                setPreviewUrl(URL.createObjectURL(files[0]))
            })
        }
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const id = organizationId.toString()
        const formData = new FormData()
        if (image !== null && typeof image !== "string") {
            formData.append("event_photo", image)
        }
        formData.append("title", title!)
        formData.append("organization", id)
        formData.append("description", textWithoutHtml)
        dispatch<any>(patchEventRequest(eventId, organizationId, formData))
    }

    let regex = /(?:#([\w\u00C0-\u017F]+)|<span[^>]*\bclass="hashtag"[^>]*>([\w\u00C0-\u017F]+)<\/span>)/gi
    const replacedText = selectedEvent!.description?.replace(regex, (match, group1, group2) => {
        const hashtag = group1 || group2
        const matchedHashtag = selectedEvent!.hashtags?.find(h => h.text.replace("&nbsp;", "") === hashtag.replace("#", ""))

        if (matchedHashtag) {
            const link = `<span class="hashtag" contenteditable="false">${hashtag.startsWith("#") ? hashtag : "#" + hashtag}</span>`
            return link
        } else {
            return match
        }
    })

    return (
        <form onSubmit={onFormSubmit} className="organization-profile-add-event">
            <div className="organization-profile-add-event__top-container">
                <div className="organization-profile-add-event__organization-container">
                    <div className="organization-profile-add-event__organization-photo">
                        <Image
                            className="image image__profile image__profile--fit image__profile--size-small"
                            src={organization.photo?.url_path || organizationProfileDefault}
                            alt="organization"
                        />
                    </div>
                    <div className="organization-profile-add-event__organization-name">{organization.name}</div>
                </div>
                <div onClick={onCloseModal} className="organization-profile-add-event__close-icon">
                    <CloseIcon />
                </div>
            </div>
            <div className="organization-profile-add-event__content">
                <div className="organization-profile-add-event__max-character-container">
                    <input
                        className="organization-profile-add-event__title-input"
                        placeholder={translation.addTitle}
                        maxLength={eventTitleMaxLength}
                        value={title}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
                    />
                    <CharacterCounter current={title?.length!} max={eventTitleMaxLength} modifier="margin-right" />
                </div>
                <div className="organization-profile-add-event__max-character-container">
                    <div className="organization-profile-add-event__description-input">
                        <EditorTextArea
                            inputState={description!}
                            setInputState={setDescription}
                            editorRef={editorRef}
                            editorPlaceholder={translation.addDescription}
                            editorHeight={72}
                            modifier={EditorModifier.eventDescription}
                            initialEditorValue={replacedText!}
                            setContent={() => {}}
                            enterPressed={() => {}}
                        />
                    </div>
                    <CharacterCounter current={textWithoutHtml?.length!} max={eventDescriptionMaxLength} modifier="margin-right" />
                </div>
                {descriptionErrorState && <p className="crop__error-message">{descriptionErrorState}</p>}
                <div className="organization-profile-add-event__image-container">
                    <input className="organization-profile-add-event__input-file" type="file" accept="image/*" onChange={onPhotoChange} />
                    {!image ? (
                        <div className="organization-profile-add-event__image-placeholder">
                            <Upload />
                            <div className="organization-profile-add-event__image-text">
                                {" "}
                                {translation.uploadWithSpace} <br />
                                {translation.imageWithSpace}
                            </div>
                            <p className="organization-profile-add-event__image-size-text">{translation.maxImageSize}</p>
                        </div>
                    ) : (
                        <Image className="organization-profile-add-event__image" src={typeof image === "string" ? image : previewUrl} alt="event" />
                    )}
                </div>
                {photoziseErrorState && <p className="crop__error-message">{translation.fileIsTooBig}</p>}
            </div>
            <div className="organization-profile-add-event__buttons_container">
                <div onClick={onEventDelete} className="organization-profile-add-event__delete-button">
                    {translation.deleteEvent}
                </div>
                <div className="organization-profile-add-event__button">
                    <Button
                        type="submit"
                        className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-gold button--hover-brighter button--cursor-pointer button--border-radius-05"
                        disabled={!isDefined(title) || !isDefined(description)}
                    >
                        {translation.save}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default OrganizationProfileEditEvent
