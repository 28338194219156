import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { headers } from "../../../App/Axios"
import { RoleType } from "../../../App/enums"
import { not_found } from "../../../App/GlobaleVariables"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import DonateButton from "../../Shared/Components/DonateButton"
import ModalGroup from "../../Shared/Components/ModalGroup"
import OrganizationProfileCard from "../../Shared/Components/OrganizationProfileCard"
import RoomDropdown from "../../Shared/Components/RoomDropdown"
import SponsorCard from "../../Shared/Components/SponsorCard"
import { SearchIcon } from "../../Shared/Components/SvgIcons"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import MainLayout from "../../Shared/Layout/MainLayout"
import NewestMembersCard from "../../Shared/NewestMembers/NewestMemberCard"
import NewestOrganizationsCard from "../../Shared/NewestOrganizations/NewestOrganizations"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import UserDashboardFeed from "./UserDashboardFeed"

function UserDashboardScreen() {
    const translation = useSelector(selectTranslations)
    const { roomInvitationToken, token, newOrgEmailToken, orgToken, newEmailToken, id } = useParams()
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const [roomId, setRoomId] = useState<number>()
    const [hasArticleCard, setHasArticleCard] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    const addInvitedUserPostRequest = useCallback(() => {
        return HTTPService.post("/add-invited-user/", { encrypted_room_id: roomInvitationToken }, headers)
    }, [roomInvitationToken])

    const getEmailVerification = useCallback(() => {
        return HTTPService.get(`/email-verify/?token=${token}`)
    }, [token])

    const getNewEmailVerification = useCallback(() => {
        return HTTPService.get(`/email-verify/?token=${token}&newemail=${newEmailToken}`)
    }, [newEmailToken, token])

    const getNewOrganzationEmailVerification = useCallback(() => {
        return HTTPService.get(`/org/verify-email/?token=${orgToken}&newemail=${newOrgEmailToken}`)
    }, [newOrgEmailToken, orgToken])

    const getOrganizationEmailVerification = useCallback(() => {
        return HTTPService.get(`/org/verify-email/?token=${orgToken}`)
    }, [orgToken])

    useEffect(() => {
        if (roomInvitationToken && roomId === undefined) {
            addInvitedUserPostRequest().then(response => {
                if (response.status === 200) {
                    setRoomId(response.data.room_id)
                }
            })
        }

        if (roomId !== undefined) {
            navigate(`/room/${roomId}`)
            dispatch(setModalData({ open: true, successfullyJoinedRoom: true }))
        }

        if (token) {
            if (newEmailToken) {
                getNewEmailVerification()
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setModalData({ open: true, postiveNewEmailVerification: true }))
                            navigate(`/dashboard`)
                        }
                    })
                    .catch(error => {
                        dispatch(setModalData({ open: true, negativeEmailVerification: { status: true, message: error.response.data.error } }))
                    })
            } else {
                getEmailVerification()
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setModalData({ open: true, postiveEmailVerification: true }))
                            navigate(`/dashboard`)
                        }
                    })
                    .catch(error => {
                        dispatch(setModalData({ open: true, negativeEmailVerification: { status: true, message: error.response.data.error } }))
                    })
            }
        }

        if (orgToken) {
            if (newOrgEmailToken) {
                getNewOrganzationEmailVerification()
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setModalData({ open: true, postiveNewOrganizationEmailVerification: true }))
                            navigate(`/dashboard`)
                        }
                    })
                    .catch(error => {
                        dispatch(setModalData({ open: true, negativeEmailVerification: { status: true, message: error.response.data.error } }))
                    })
            } else {
                getOrganizationEmailVerification()
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setModalData({ open: true, postiveOrganziationEmailVerification: true }))
                            navigate(`/dashboard`)
                        }
                    })
                    .catch(error => {
                        dispatch(setModalData({ open: true, negativeEmailVerification: { status: true, message: error.response.data.error } }))
                    })
            }
        }
    }, [
        addInvitedUserPostRequest,
        dispatch,
        getEmailVerification,
        getNewEmailVerification,
        getNewOrganzationEmailVerification,
        getOrganizationEmailVerification,
        navigate,
        newEmailToken,
        newOrgEmailToken,
        orgToken,
        roomId,
        roomInvitationToken,
        token,
    ])

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={<OrganizationProfileCard />}
                leftPanelSecondComponent={<RoomDropdown type={RoleType.USER} />}
                //leftPanelForthComponent={translation.newestOrganizations}
                //leftPanelFifthComponent={<NewestOrganizationsCard />}
                RightPanelFirstComponent={donationLink === not_found ? <DonateButton /> : null}
                //RightPanelSecondComponent={translation.newsMembersText}
                //RightPanelThirdComponent={<NewestMembersCard />}
                RightPanelForthComponent={
                    <div className="main-layout__sponsored-title">
                        {translation.sponsoredText}
                        <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                            <SearchIcon color="dark-blue" />
                        </div>
                    </div>
                }
                RightPanelFifthComponent={<SponsorCard />}
                overflowModifier={true}
                isDashboad={hasArticleCard}
            >
                
                <UserDashboardFeed hasArticleCard={hasArticleCard} />
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default UserDashboardScreen
