import React, { useEffect, useState } from "react"
import Header from "../../../Shared/Header/Header"
import Footer from "../../../Shared/Layout/Footer"
import IndividualProfileCard from "../../../Shared/Components/IndividualProfileCard"
import MainLayout from "../../../Shared/Layout/MainLayout"
import ModalGroup from "../../../Shared/Components/ModalGroup"
import RoomDropdown from "../../../Shared/Components/RoomDropdown"
import OrganizationProfileCoverPhoto from "../OrganizationProfileCoverPhoto"
import OrganizationProfileHeader from "../OrganizationProfileHeader"
import OrganizationProfileOurRooms from "./OrganizationProfileOurRooms"
import { RoleType, StorageKey, SubscriptionType } from "../../../../App/enums"
import OrganizationProfilePinboardContainer from "../OrganizationPinboard/OrganizationProfilePinboardContainer"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import HTTPService from "../../../../App/HTTPService"
import { headers } from "../../../../App/Axios"
import OrganizationProfileCard from "../../../Shared/Components/OrganizationProfileCard"
import GoPremiumCard from "../../../Shared/Components/GoPremiumCard"
import NewestOrganizationsCard from "../../../Shared/NewestOrganizations/NewestOrganizations"
import { getLoadingStatus } from "../../../Shared/SharedSlices/LoadingScreenSlice"
import LoadingScreen from "../../../Shared/Layout/LoadingScreen"
import { Organization } from "../../../../Types/Organization"
import { setRoleData, setRoleStatusData } from "../../../Shared/SharedSlices/RolesSlice"
import SessionService from "../../../../App/SessionService"
import { GetOrganizationRequest } from "../../OrganizationDashboard/GetOrganizationServiceRequest"
import BackgroundWrapper from "../../../Shared/Components/BackgroundWrapper"

function OrganizationProfileOurRoomsScreen() {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { id, otherOrgId } = useParams()
    const [isYourOrg, setIsYourOrg] = useState(false)
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const loadingStatus = useSelector((state: RootState) => state.LoadingStatusSlice.status)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [otherOrganizationData, setOtherOrganizationData] = useState<Organization>()
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const getOrganizationData = (orgId: string) => {
        return HTTPService.get(`/role-in-org/${orgId}/`, headers)
    }

    useEffect(() => {
        let isMounted = true
        dispatch(getLoadingStatus(true))
        if (otherOrgId) {
            getOrganizationData(otherOrgId!).then(response => {
                if (isMounted && response.status === 200) {
                    setIsYourOrg(response.data.is_admin)
                    if (response.data.is_admin) {
                        dispatch(setRoleStatusData(true))
                        dispatch(setRoleData(RoleType.ORGANIZATION))
                        SessionService.setItem(StorageKey.roles, RoleType.ORGANIZATION)
                        SessionService.setItem(StorageKey.organizationId, otherOrgId!)
                        dispatch<any>(GetOrganizationRequest(id, userDataState, navigate, location))
                    } else {
                        setOtherOrganizationData(response.data.organization)
                    }
                    dispatch(getLoadingStatus(false))
                }
            })
        } else {
            getOrganizationData(id!).then(response => {
                if (isMounted && response.status === 200) {
                    setIsYourOrg(response.data.is_admin)
                    if (response.data.is_admin) {
                        dispatch(setRoleStatusData(true))
                        dispatch(setRoleData(RoleType.ORGANIZATION))
                        SessionService.setItem(StorageKey.roles, RoleType.ORGANIZATION)
                        SessionService.setItem(StorageKey.organizationId, id!)
                    } else {
                        setOtherOrganizationData(response.data.organization)
                    }
                    dispatch(getLoadingStatus(false))
                }
            })
        }
        return () => {
            isMounted = false
        }
    }, [otherOrgId, id, dispatch])

    return (
        <>
            {loadingStatus ? (
                <LoadingScreen />
            ) : (
                <BackgroundWrapper>
                    <Header />
                    <OrganizationProfileCoverPhoto isYourOrganization={isYourOrg} otherOrganization={otherOrganizationData!} />
                    <MainLayout
                        leftPanelFirstComponent={role === RoleType.ORGANIZATION ? <IndividualProfileCard /> : <OrganizationProfileCard />}
                        leftPanelSecondComponent={
                            role === RoleType.ORGANIZATION ? <RoomDropdown type={RoleType.ORGANIZATION} /> : <RoomDropdown type={RoleType.USER} />
                        }
                        leftPanelThirdComponent={
                            role === RoleType.ORGANIZATION ? (
                                organization.subscription_type === SubscriptionType.free ? (
                                    <GoPremiumCard shape="tall" text={translation.upgradeYourProfile} />
                                ) : null
                            ) : null
                        }
                        leftPanelForthComponent={translation.newestOrganizations}
                        leftPanelFifthComponent={<NewestOrganizationsCard />}
                        RightPanelFirstComponent={<OrganizationProfilePinboardContainer isYourOrganization={isYourOrg} hasTitle={true} />}
                    >
                        <div className="organization-profile-rooms-screen ">
                            <OrganizationProfileHeader isYourOrganization={isYourOrg} otherOrganization={otherOrganizationData!} />
                            <div className="organization-profile-rooms-screen__rooms-container">
                                <OrganizationProfileOurRooms
                                    isExpandedScreen={true}
                                    isYourOrganization={isYourOrg}
                                    hasSearchCategories={true}
                                    createRoom={isYourOrg}
                                    hasButton={false}
                                    hasSearch={true}
                                    filterNumber={5000}
                                />
                            </div>
                        </div>
                    </MainLayout>
                    <Footer />
                    <ModalGroup />
                </BackgroundWrapper>
            )}
        </>
    )
}

export default OrganizationProfileOurRoomsScreen
