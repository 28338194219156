import React, { useEffect, useState } from "react"
import StorageService from "../../../App/StorageService"
import { StorageKey } from "../../../App/enums"
import "../../../Styles/main.scss"


function Image(props: React.ImgHTMLAttributes<HTMLImageElement>) {
    const imageProps: React.ImgHTMLAttributes<HTMLImageElement> = {
        ...props,
    }
    const [isVisible,setIsVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState('')

    const accessToken = StorageService.getItem(StorageKey.accessToken) as string

    const addCacheItem = (key:string, value:string, ttl:number) => {
        const now = new Date()
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        try{
            sessionStorage.setItem(key, JSON.stringify(item))
        }
        catch{
            //item too large for session cache
        }
    }

    const getCacheItem = (key:string) => {
        const itemStr = sessionStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        if (now.getTime() > item.expiry) {
            sessionStorage.removeItem(key)
            return null
        }
        return item.value
    }
    

    const getBase64Image = async (res: any) => {
        const blob = await res.blob();

        const reader = new FileReader();

        await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });

        return reader.result;
    };

    useEffect(() => {
        if(imageProps.src?.includes('3000')){
            imageProps.src = imageProps.src?.replace('3000', '8000')
            imageProps.src = imageProps.src?.replace('https://','http://')
        }
        if (imageProps.src?.startsWith('/media') || imageProps.src?.includes(process.env.REACT_APP_BASE_API_URL!.replace('api', ''))) {

            let split = imageProps.src.split('/')
            let filename = split[split.length-1]

            let cachedItem = getCacheItem(filename)

            if(cachedItem != null){
                setImageSrc(cachedItem)
                setIsVisible(true)
                return;
            }

            imageProps.src = imageProps.src?.replace('3000', '8000')
            if (window.location.hostname === 'localhost' && imageProps.src!.startsWith('/media') || imageProps.src!.startsWith('/media')) {
                imageProps.src = process.env.REACT_APP_BASE_API_URL!.replace('api', '') + imageProps.src
            }

            imageProps.src = imageProps.src.replace('//media','/media')

            fetch(imageProps.src!, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
            }).catch()
                .then(getBase64Image)
                .then(imgString => {
                    setImageSrc(imgString as string)
                    setIsVisible(true)

                    addCacheItem(filename,imgString as string,3600000)
                });
        }else{
            setIsVisible(true)
            setImageSrc(imageProps.src!)
        }
    }, [accessToken, imageProps, props, props.src])


    return <img {...imageProps} style={{display: isVisible ? 'block' : 'none'}} alt='' src={imageSrc}></img>
}
export default Image
