import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import EdusiiaMatchingScreen from "../../Module/Authentication/EdusiiaMatching/EdusiiaMatchingScreen"
import EmailConfirmationScreen from "../../Module/Authentication/EmailConfirmation/EmailConfirmationScreen"
import SignupScreen from "../../Module/Authentication/SignUp/SignupScreen"
import SigninScreen from "../../Module/Authentication/SignIn/SigninScreen"
import ResetPasswordScreen from "../../Module/Authentication/ResetPassword/ResetPasswordScreen"
import ForgotPasswordScreen from "../../Module/Authentication/ForgotPassword/ForgotPasswordScreen"
import PrivateRoute from "./PrivateRoute"
import PublicRoute from "./PublicRoute"
import UserDashboardScreen from "../../Module/User/UserDashboard/UserDashboardScreen"
import FrequentlyAskedQuestions from "../../Module/Shared/FAQ/FAQ"
import UserProfileScreen from "../../Module/User/UserProfile/UserProfileScreen"
import UserProfileRoomsScreen from "../../Module/User/UserProfile/UserProfileRooms/UserProfileRoomsScreen"
import UserProfileExperienceScreen from "../../Module/User/UserProfile/MyExperience/UserProfileExperienceScreen"
import NewestMembersScreen from "../../Module/Shared/NewestMembers/NewestMembersScreen"
import OrganizationProfileEmployeesScreen from "../../Module/Organization/OrganizationProfile/OrganizationProfileEmpoyees/OrganizationProfileEmployeesScreen"
import OrganizationProfileScreen from "../../Module/Organization/OrganizationProfile/OrganizationProfileScreen"
import OrganizationDashboardScreen from "../../Module/Organization/OrganizationDashboard/OrganizationDashboardScreen"
import MyOrganizationsScreen from "../../Module/User/MyOrganizations/MyOrganizationsScreen"
import DonationScreen from "../../Module/User/Donation/DonationScreen"
import OtherUserPofileScreen from "../../Module/OtherUsers/OtherUserPofileScreen"
import OtherUserExperienceScreen from "../../Module/OtherUsers/OtherUserExperienceScreen"
import OtherUserRoomsScreen from "../../Module/OtherUsers/OtherUserRoomScreen"
import NoPermissionPage from "../../Module/Shared/Layout/NoPermissionPage"
import RoomScreen from "../../Module/Room/Layouts/RoomScreen"
import GoPremiumScreen from "../../Module/Organization/GoPremium/GoPremiumScreen"
import OrganizationProfileOurRoomsScreen from "../../Module/Organization/OrganizationProfile/OrganizationRooms/OrganizationProfileOurRoomsScreen"
import { RoleType, StorageKey } from "../enums"
import NewestOrganizationsScreen from "../../Module/Shared/NewestOrganizations/NewestOrganizationScreen"
import UserNotificationsScreen from "../../Module/Notifications/NotificationsScreen"
import RoomScreenPostSelected from "../../Module/Room/Layouts/RoomScreenPostSelected"
import SearchScreen from "../../Module/Shared/Search/SearchScreen"
import SessionService from "../SessionService"
import { Role } from "../../Types/RoleType"
import NotFound from "../../Module/Shared/Layout/NotFound"
import SuccessfulOrder from "../../Module/Shared/Layout/SuccessfulOrder"
import OrganizationRoomScreen from "../../Module/Room/Layouts/OrganizationRoomScreen"
import OrganizationRoomScreenPostSelected from "../../Module/Room/Layouts/OrganizationRoomScreenPostSelected"
import RoomsScreen from "../../Module/Shared/AllRooms/AllRoomsScreen"
import AllRoomsScreen from "../../Module/Shared/AllRooms/AllRoomsScreen"
import HashtagScreen from "../../Module/Shared/Hashtag/HashtagScreen"
import AllSponsoredEventsScreen from "../../Module/Shared/AllSponsoredEvents/AllSponsoredEventsScreen"
import InvitationScreen from "../../Module/Shared/InvitationScreen/InvitationScreen"
import CreateOrganizationScreen from "../../Module/Authentication/CreateOrganization/CreateOrganizationScreen"
import UserChatScreen from "../../Module/Chat/Layout/UserChatScreen"
import LocationScreen from "../../Module/Shared/Location/LocationScreen"

const Routing = () => {
    const eventRole = SessionService.getItem(StorageKey.roles)
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/emailconfirmation" element={<PublicRoute Component={EmailConfirmationScreen} />} />
                    <Route path="/edusiiamatching" element={<PublicRoute Component={EdusiiaMatchingScreen} />} />
                    <Route path="/create-organization" element={<PublicRoute Component={CreateOrganizationScreen} />} />
                    <Route path="/email-verification/:token" element={<PublicRoute Component={SigninScreen} />} />
                    <Route path="/" element={<PublicRoute Component={SigninScreen} />} />
                    <Route path="/signin" element={<PublicRoute Component={SigninScreen} />} />
                    <Route path="/signup" element={<PublicRoute Component={SignupScreen} />} />
                    <Route path="/password-change/:uidb64/:token" element={<PublicRoute Component={ResetPasswordScreen} />} />
                    <Route path="/forgotpassword" element={<PublicRoute Component={ForgotPasswordScreen} />} />
                    <Route path="/dashboard" element={<PrivateRoute Component={UserDashboardScreen} Role={RoleType.USER} />} />
                    <Route path="/FAQ" element={<PrivateRoute Component={FrequentlyAskedQuestions} Role={RoleType.USER} />} />
                    <Route path="/user-profile" element={<PrivateRoute Component={UserProfileScreen} Role={RoleType.USER} />} />
                    <Route path="/all-rooms" element={<PrivateRoute Component={UserProfileRoomsScreen} Role={RoleType.USER} />} />
                    <Route path="/all-experience" element={<PrivateRoute Component={UserProfileExperienceScreen} Role={RoleType.USER} />} />
                    <Route path="/newest-members" element={<PrivateRoute Component={NewestMembersScreen} Role={RoleType.USER} />} />
                    <Route path="/newest-organizations" element={<PrivateRoute Component={NewestOrganizationsScreen} Role={RoleType.USER} />} />
                    <Route path="/notifications" element={<PrivateRoute Component={UserNotificationsScreen} Role={RoleType.USER} />} />
                    <Route path="/my-organizations" element={<PrivateRoute Component={MyOrganizationsScreen} Role={RoleType.USER} />} />
                    <Route path="/user/:userId" element={<PrivateRoute Component={OtherUserPofileScreen} Role={RoleType.USER} />} />
                    <Route path="/all-user-experience/:userId" element={<PrivateRoute Component={OtherUserExperienceScreen} Role={RoleType.USER} />} />
                    <Route path="/all-user-rooms/:userId" element={<PrivateRoute Component={OtherUserRoomsScreen} Role={RoleType.USER} />} />
                    <Route path="/room/:roomId" element={<PrivateRoute Component={RoomScreen} Role={RoleType.USER} />} />
                    <Route path="/room-post/:roomId/:postId" element={<PrivateRoute Component={RoomScreenPostSelected} Role={RoleType.USER} />} />
                    <Route path="/search-screen" element={<PrivateRoute Component={SearchScreen} Role={RoleType.USER} />} />
                    <Route path="/donation" element={<PrivateRoute Component={DonationScreen} Role={RoleType.USER} />} />
                    <Route path="/organization-profile/:id" element={<PrivateRoute Component={OrganizationProfileScreen} Role={eventRole! as Role} />} />
                    <Route path="/organization-rooms/:id" element={<PrivateRoute Component={OrganizationProfileOurRoomsScreen} Role={eventRole! as Role} />} />
                    <Route
                        path="/organization-employees/:id"
                        element={<PrivateRoute Component={OrganizationProfileEmployeesScreen} Role={eventRole! as Role} />}
                    />
                    <Route
                        path="/organization-profile/:id/event/:eventId"
                        element={<PrivateRoute Component={OrganizationProfileScreen} Role={eventRole! as Role} />}
                    />
                    <Route path="/organization-dashboard/:id" element={<PrivateRoute Component={OrganizationDashboardScreen} Role={RoleType.ORGANIZATION} />} />{" "}
                    <Route path="/FAQ/:id" element={<PrivateRoute Component={FrequentlyAskedQuestions} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/newest-members/:id/" element={<PrivateRoute Component={NewestMembersScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/newest-organizations/:id/" element={<PrivateRoute Component={NewestOrganizationsScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/notifications/:id" element={<PrivateRoute Component={UserNotificationsScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/user/:userId/:id" element={<PrivateRoute Component={OtherUserPofileScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route
                        path="/:id/all-user-experience/:userId"
                        element={<PrivateRoute Component={OtherUserExperienceScreen} Role={RoleType.ORGANIZATION} />}
                    />
                    <Route path="/search-screen/:id" element={<PrivateRoute Component={SearchScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/all-user-rooms/:id/:userId" element={<PrivateRoute Component={OtherUserRoomsScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/room/:id/:roomId" element={<PrivateRoute Component={OrganizationRoomScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route
                        path="/room-post/:id/:roomId/:postId"
                        element={<PrivateRoute Component={OrganizationRoomScreenPostSelected} Role={RoleType.ORGANIZATION} />}
                    />
                    <Route path="/go-premium/:id" element={<PrivateRoute Component={GoPremiumScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/search-screen/:id" element={<PrivateRoute Component={SearchScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/no-permission" element={<PrivateRoute Component={NoPermissionPage} Role={RoleType.USER} />} />
                    <Route path="/invitation-link/:roomInvitationToken" element={<PrivateRoute Component={UserDashboardScreen} Role={RoleType.USER} />} />
                    <Route path="/org-email-verification/:orgToken" element={<PrivateRoute Component={UserDashboardScreen} Role={RoleType.USER} />} />
                    <Route
                        path="/new-org-email-verification/:orgToken/newemail/:newOrgEmailToken"
                        element={<PrivateRoute Component={UserDashboardScreen} Role={RoleType.USER} />}
                    />
                    <Route path="/not-found" element={<PrivateRoute Component={NotFound} Role={RoleType.USER} />} />
                    <Route path="*" element={<PrivateRoute Component={NotFound} Role={RoleType.USER} />} />
                    <Route path="/successful-order" element={<PrivateRoute Component={SuccessfulOrder} Role={RoleType.USER} />} />
                    <Route
                        path="/new-email-verification/:token/newemail/:newEmailToken"
                        element={<PrivateRoute Component={UserDashboardScreen} Role={RoleType.USER} />}
                    />
                    <Route path="/rooms" element={<PrivateRoute Component={RoomsScreen} Role={RoleType.USER} />} />
                    <Route path="/rooms/:id" element={<PrivateRoute Component={AllRoomsScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/hashtag" element={<PrivateRoute Component={HashtagScreen} Role={RoleType.USER} />} />
                    <Route path="/:id/hashtag" element={<PrivateRoute Component={HashtagScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/all-premium-events" element={<PrivateRoute Component={AllSponsoredEventsScreen} Role={RoleType.USER} />} />
                    <Route path="/all-premium-events/:id" element={<PrivateRoute Component={AllSponsoredEventsScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/invitations" element={<PrivateRoute Component={InvitationScreen} Role={RoleType.USER} />} />
                    <Route path="/invitations/:id" element={<PrivateRoute Component={InvitationScreen} Role={RoleType.ORGANIZATION} />} />
                    <Route path="/chat" element={<PrivateRoute Component={UserChatScreen} Role={RoleType.USER} /> } />
                    <Route path="/location/:state_id" element={<PrivateRoute Component={LocationScreen} Role={eventRole! as Role} />} />
                    
                </Routes>
            </Router>
        </>
    )
}

export default Routing
