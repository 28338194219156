import { useState } from "react"
import "../../../Styles/main.scss"

function Tooltip(props: { delay?: number; children?: any; direction?: string; content?: any }) {
    let timeout: ReturnType<typeof setTimeout>
    const [active, setActive] = useState(false)

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true)
        }, props.delay || 500)
    }

    const hideTip = () => {
        clearInterval(timeout)
        setActive(false)
    }

    return (
        <div data-testid="content" className="tooltip__wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
            <div className="tooltip__icon">{props.children}</div>
            {active && <div className={`tooltip__tip  tooltip__tip--${props.direction || "top"}`}>{props.content}</div>}
        </div>
    )
}

export default Tooltip
