import "../../../Styles/main.scss"
import SearchInput from "../../Shared/Primitive/SearchInput"
import { useDispatch } from "react-redux"
import { setSearchQuery } from "./UserProfileRooms/UserRoomSearchSlice"

function UserProfileCard(props: {
    inputValue?: string
    setInputValue?: Function
    onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    isFirstCategorySelected?: boolean
    onFirstCategoryClick?: () => void
    onSecondCategoryClick?: () => void
    firstCategory?: string
    secondCategory?: string
    children?: any
    button?: JSX.Element | null
    hasSearchField?: boolean
    hasCategories?: boolean
    title?: string
}) {
    const dispatch = useDispatch()
    const onRemoveIconClick = () => {
        props.setInputValue?.("")
        dispatch(setSearchQuery(""))
    }
    return (
        <div className="user-profile-card">
            {props.title && <div className="user-profile-card__title">{props.title}</div>}

            <div className="user-profile-card__search-container">
                {props.hasCategories && (
                    <div className="user-profile-card__search-options">
                        <div
                            onClick={props.onFirstCategoryClick}
                            className={`user-profile-card__filter-item ${props.isFirstCategorySelected && "user-profile-card__filter-item--selected"}`}
                        >
                            {props.firstCategory}
                        </div>
                        <div
                            onClick={props.onSecondCategoryClick}
                            className={`user-profile-card__filter-item ${!props.isFirstCategorySelected && "user-profile-card__filter-item--selected"}`}
                        >
                            {props.secondCategory}
                        </div>
                    </div>
                )}
                {props.hasSearchField && (
                    <div className="user-profile-card__search-input">
                        <SearchInput
                            onRemoveIconClick={onRemoveIconClick}
                            searchvalue={props.inputValue!}
                            onChange={props.onInputChange}
                            isGlobalSearch={false}
                        />
                    </div>
                )}
            </div>

            <div className="user-profile-card__content">{props.children}</div>
            <div className="user-profile-card__button">{props.button}</div>
        </div>
    )
}

export default UserProfileCard
