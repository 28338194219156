import DOMPurify from "dompurify"
import { htmlRegex, urlRegex, validEmail } from "../../../App/Regex"
import { useDispatch, useSelector } from "react-redux"
import { ReactTinyLink } from "react-tiny-link"
import { useState } from "react"
import { SmallCloseIcon } from "./SvgIcons"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setRoomPostId, setRoomCommentId, setRoomEventCommentId } from "../../Room/RoomPost/RoomPostSlice"
import { CallerComponent } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import linkifyHtml from "linkify-html"
import useWindowSize from "../Hooks/useWindowSize"
import { onNavigationToExpandedPost } from "../../Room/RoomPost/onNavigation"

function MessageBody(props: {
    text: string
    showPreview: boolean
    caller?: string
    id?: number
    showCollapsed: boolean
    showRemovePreviewIcon: boolean
    isCollapsed?: boolean
    toggleCollapse?: () => void
    onNavigationToExpandedPost?: () => void
    hashtags: Array<string | { text: string }>
}) {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const [hasError, setHasError] = useState(false)
    const [hasImage, setHasImage] = useState(false)
    const screenWidth = useWindowSize()
    const breakpoint = 1023
    const maxContentLength = props.caller === CallerComponent.roomDescription ? 81 : screenWidth > breakpoint ? 500 : 300

    const cleanHtml = DOMPurify.sanitize(props.text, {
        ALLOWED_TAGS: ["b", "em","strong", "p", "span", "a", "div", "img", "header", "footer","ul","ol","li"],
        ALLOWED_ATTR: ["class", "href", "target", "data-type", "className", "id", "rel", "src", "style", "width", "type", "data-reactroot"],
    })

    const textWithoutHtml = cleanHtml.replace(htmlRegex, "")

    const handleClick = (e : React.MouseEvent<HTMLDivElement,MouseEvent>) => {
        if(!(e.target instanceof HTMLAnchorElement)){
            props.onNavigationToExpandedPost!()
        }
    }

    const messageBodyClass = () => {
        if (props.showCollapsed) {
            if (!props.isCollapsed && textWithoutHtml.length > maxContentLength) return "truncated"
        } else if (hasLinkPreview() && props.caller === CallerComponent.feed) {
            return "message-body-link-feed"
        }
        return "message-body"
    }

    const hasLinkPreview = () => {
        const textWithoutHtml = cleanHtml.replaceAll(htmlRegex, "")

        if (textWithoutHtml.match(urlRegex) && !textWithoutHtml.match(validEmail)) {
            if (textWithoutHtml.match(urlRegex)![0]?.includes("https://") || textWithoutHtml.match(urlRegex)![0]?.includes("http://")) {
                return textWithoutHtml.match(urlRegex)![0]
            } else {
                return `http://${textWithoutHtml.match(urlRegex)![0]}`
            }
        }
        return null
    }

    const handleRemoveLink = () => {
        dispatch(setModalData({ open: true, deleteLinkPreview: true }))
        switch (props.caller) {
            case CallerComponent.post:
                props.id && dispatch(setRoomPostId(props.id))
                break
            case CallerComponent.comment:
                props.id && dispatch(setRoomCommentId(props.id))
                break
            case CallerComponent.eventComment:
                props.id && dispatch(setRoomEventCommentId(props.id))
                break
        }
    }

    const hashtagRegex = /(?:#([\w\u00C0-\u017F]+)|<span[^>]*\bclass="hashtag"[^>]*>(.*?)<\/span>)/gi
    const replacedText = cleanHtml.replace(hashtagRegex, (match, group1, group2) => {
        const hashtag = group1 || group2

        const matchedHashtag = props.hashtags?.find((h: string | { text: string }) => {
            if (typeof h === "object") {
                return h.text.replace("&nbsp;", "") === hashtag.replace("#", "")
            } else {
                return h.replace("&nbsp;", "") === hashtag.replace("#", "")
            }
        })

        if (matchedHashtag) {
            const link = `<a href="hashtag?keyword=${hashtag.replace("#", "")}" rel="noopener" class="hashtag" target="_blank">${
                hashtag.startsWith("#") ? hashtag : "#" + hashtag
            }</a>`

            return link
        } else {
            return match
        }
    })

    return (
        <div>
            <div
                onClick={ (e) => handleClick(e)}
                className={messageBodyClass()}
                id="message-body"
                dangerouslySetInnerHTML={{ __html: linkifyHtml(cleanHtml) }}
                ></div> 

            {props.caller !== CallerComponent.eventDescription && props.showCollapsed && textWithoutHtml.length > maxContentLength && (
                <div
                    onClick={props.toggleCollapse}
                    className={`${
                        props.caller === CallerComponent.roomDescription
                            ? "button-textPreview-more button-textPreview-more--red"
                            : "button-textPreview-more button-textPreview-more--blue"
                    }`}
                >
                    {!props.isCollapsed ? translation.seeMoreText : translation.seeLessText}
                </div>
            )}

            {props.showPreview && props.caller !== CallerComponent.eventComment && hasLinkPreview() !== null && !hasError ? (
                <>
                    {props.showRemovePreviewIcon && (
                        <div className="clear-link-icon">
                            <div onClick={handleRemoveLink}>
                                <SmallCloseIcon />
                            </div>
                        </div>
                    )}
                    <ReactTinyLink
                        cardSize="small"
                        showGraphic={hasImage}
                        maxLine={2}
                        minLine={1}
                        proxyUrl={`${process.env.REACT_APP_BASE_API_URL}/cors-proxy/?url=`}
                        url={hasLinkPreview()}
                        onSuccess={(data: any) => {
                            if (data["image"].length > 0) {
                                setHasImage(true)
                            }
                        }}
                        onError={(error: any) => {
                            setHasError(true)
                        }}
                    />
                </>
            ) : null}
        </div>
    )
}

export default MessageBody
