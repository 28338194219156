import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import SessionService from "../../../App/SessionService"
import StorageService from "../../../App/StorageService"
import { RoleType, StorageKey } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { PatchOrganizationTutorialProgressRequest } from "../../Organization/OrganizationSettings/OrganizationAccountServiceRequest"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setOrganizationData } from "../../Shared/SharedSlices/OrganizationsSlice"
import { setUserData } from "../../Shared/SharedSlices/UserDataSlice"
import { updateUserTutorialProgressRequest } from "../UserProfile/UserTutorialProgressRequest"

function ShowTutorialModal() {

    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const userData = useSelector((state: RootState) => state.userDataSlice)
    const orgData = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const userId = useSelector((state: RootState) => state.userDataSlice.id)
    const orgId = SessionService.getItem(StorageKey.organizationId)

    const closeModal = () => {
        dispatch(setModalData({ open: false, showTutorial: false }))

    }
    const handleStart = () => {
        switch (role) {
            case RoleType.USER:
                updateUserTutorialProgressRequest(userData, { tutorial_progress: 1 }).then(() => {
                    dispatch(
                        setUserData({
                            ...userData,
                            tutorial_progress: 1,
                        })
                    )
                })
                break
            case RoleType.ORGANIZATION:
                PatchOrganizationTutorialProgressRequest(orgData.id!, 1).then(() => {
                    dispatch(
                        setOrganizationData({
                            ...orgData,
                            tutorial_progress: 1,
                        })
                    )
                })
                break
        }
        closeModal()
    }
    const handleSkip = () => {
        switch (role) {
            case RoleType.USER:
                updateUserTutorialProgressRequest(userData, { tutorial_progress: 100 }).then(() => {
                    dispatch(
                        setUserData({
                            ...userData,
                            tutorial_progress: 100,
                        })
                    )
                })
                break
            case RoleType.ORGANIZATION:
                PatchOrganizationTutorialProgressRequest(orgData.id!, 100).then(() => {
                    dispatch(
                        setOrganizationData({
                            ...orgData,
                            tutorial_progress: 100,
                        })
                    )
                })
                break
                break
        }
        closeModal()
    }

    useEffect(() => {
        if(Object.keys(userData).length > 0){
            StorageService.setItem("user", StorageService.stringify(userData))
        }
    }, [userData.tutorial_progress])

    return (
        <div className="show-tutorial-modal">
            <div className="show-tutorial-modal__title-container">
                <p className="show-tutorial-modal__title">{translation.viedoWelcomeText} </p>
                <div className="show-tutorial-modal__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </div>
            </div>
            <div className="show-tutorial-modal__content-container"  >
                <div className="show-tutorial-modal__explanation-info" >
                    <p className="show-tutorial-modal__info"  >{role == RoleType.USER ? translation.videoExplanationText : translation.tutorial_org_welcome_text} </p>
                </div>
                <div className="show-tutorial-modal__buttons-container">
                    <div className="show-tutorial-modal__button-size">
                        <Button
                            onClick={handleStart}
                            className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                        >
                            {translation.startTutorial}
                        </Button>

                    </div>
                    <div className="show-tutorial-modal__button-size">
                        <Button
                            onClick={handleSkip}
                            className="button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                        >
                            {translation.skipTutorial}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShowTutorialModal
