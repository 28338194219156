import React, { useState } from "react"
import Cropper from "react-easy-crop"
import getCroppedImg from "../../../App/react-easy-crop/cropImage"
import { Point } from "react-easy-crop/types"
import "../../../Styles/main.scss"
import { CloseIcon, LoadingAnimation } from "../Components/SvgIcons"
import Button from "../Primitive/Button"
import { useDispatch, useSelector } from "react-redux"
import { setModalData } from "../SharedSlices/ModalSlice"
import { dataURLtoFile } from "../../../App/react-easy-crop/dataURLtoFile"
import {
    getCoverPhotoDropdownStatus,
    getLoadingCoverPhotoDropdownStatus,
    getLoadingOrganizationCoverPhotoDropdownStatus,
    getLoadingOrganizationProfilePhotoDropdownStatus,
    getLoadingProfilePhotoDropdownStatus,
    getOrganizationCoverPhotoDropdownStatus,
    getOrganizationProfilePhotoDropdownStatus,
    getProfilePhotoDropdownStatus,
    resetProfilePhotoError,
} from "../SharedSlices/PhotoDropdownSlice"
import {
    CoverPhotoPutRequest,
    OrganizationCoverPhotoPutRequest,
    OrganizationProfilePhotoPutRequest,
    ProfilePhotoPutRequest,
} from "./PhotoDropdownServiceRequests"
import { RootState } from "../../../App/store"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { useParams } from "react-router-dom"
import { PhotoTypeModal } from "../../../App/enums"
import { getPhotoSizeErrorMessage } from "../SharedSlices/RoomSlice"

interface cropProps {
    imageUrl: string | null
    cropInit: Point
    zoomInit: number
    aspectInit: number
}

function PhotoCropModal(props: cropProps) {
    const translation = useSelector(selectTranslations)
    const [zoom, setZoom] = useState(props.zoomInit)
    const [crop, setCrop] = useState(props.cropInit)
    const [croppedArea, setCroppedArea] = useState({ width: 0, height: 0, x: 0, y: 0 })
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const modalStatus = useSelector((state: RootState) => state.modalSlice)
    const photoziseErrorState = useSelector((state: RootState) => state.roomSlice.photoSizeErrorSlice.message)
    const profilePhotoError = useSelector((state: RootState) => state.photoDropdownSlice.profilePhotoErrorSlice.message)
    const [loading, setLoadImage] = useState(false)
    const dispatch = useDispatch()
    const { id } = useParams()

    const onModalClose = () => {
        dispatch(setModalData({ open: false, settings: false, viewPhotoTypeModal: null, changePhotoTypeModal: null }))
        dispatch(getProfilePhotoDropdownStatus({ status: false }))
        dispatch(getCoverPhotoDropdownStatus({ status: false }))
        dispatch(getOrganizationCoverPhotoDropdownStatus({ status: false }))
        dispatch(getOrganizationProfilePhotoDropdownStatus({ status: false }))
        dispatch(getPhotoSizeErrorMessage(false))
        dispatch(resetProfilePhotoError())
    }

    const onCropComplete = (
        croppedAreaPercentage: { x: number; y: number; width: number; height: number },
        croppedAreaPixels: { width: number; height: number; x: number; y: number }
    ) => {
        setCroppedArea(croppedAreaPixels)
    }

    const onCropChange = (crop: Point) => {
        setCrop(crop)
    }

    const onZoomChange = (zoom: number) => {
        setZoom(zoom)
    }

    const onPhotoUpload = async () => {
        const canvas = await getCroppedImg(props.imageUrl!, croppedArea)
        const canvasDataUrl = canvas.toDataURL("image/jpeg")
        const convertedUrlToFile = dataURLtoFile(canvasDataUrl, "cropped-image.jpeg")
        setLoadImage(false)
        const formData = new FormData()
        formData.append("url_path", convertedUrlToFile)
        doPhotoUpload(formData)
        dispatch(resetProfilePhotoError())
    }

    const doPhotoUpload = async (formData: FormData) => {
        switch (modalStatus.changePhotoTypeModal) {
            case PhotoTypeModal.USER_PROFILE:
                dispatch<any>(ProfilePhotoPutRequest(userDataState, formData, setLoadImage))
                setLoadImage(true)
                dispatch(getLoadingProfilePhotoDropdownStatus({ loading: true }))
                break
            case PhotoTypeModal.USER_COVER:
                dispatch<any>(CoverPhotoPutRequest(userDataState, formData, setLoadImage))
                setLoadImage(true)
                dispatch(getLoadingCoverPhotoDropdownStatus({ loading: true }))
                break
            case PhotoTypeModal.ORGANIZATION_PROFILE:
                dispatch<any>(OrganizationProfilePhotoPutRequest(id, userDataState, formData, setLoadImage))
                setLoadImage(true)
                dispatch(getLoadingOrganizationProfilePhotoDropdownStatus({ loading: true }))
                break
            case PhotoTypeModal.ORGANIZATION_COVER:
                dispatch<any>(OrganizationCoverPhotoPutRequest(id, userDataState, formData, setLoadImage))
                setLoadImage(true)
                dispatch(getLoadingOrganizationCoverPhotoDropdownStatus({ loading: true }))
                break
            default:
                break
        }
    }

    return (
        <div className="crop-modal">
            <div className="crop-modal__icon" onClick={onModalClose}>
                <CloseIcon />
            </div>
            <div className="crop">
                {loading ? (
                    <LoadingAnimation type="cover" />
                ) : (
                    <div className="crop__size">
                        <div className="crop__container">
                            <Cropper
                                image={props.imageUrl!}
                                zoom={zoom}
                                crop={crop}
                                aspect={props.aspectInit}
                                onCropChange={onCropChange}
                                onZoomChange={onZoomChange}
                                onCropComplete={onCropComplete}
                            />
                        </div>
                    </div>
                )}

                {!photoziseErrorState && <p className="crop__error-message">{profilePhotoError}</p>}

                {photoziseErrorState && <p className="crop__error-message">{translation.fileIsTooBig}</p>}
            </div>
            <div className="crop-modal__button">
                <Button
                    className="button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                    onClick={onPhotoUpload}
                >
                    {translation.crop}
                </Button>
            </div>
        </div>
    )
}

export default PhotoCropModal
