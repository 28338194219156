import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { eventDescriptionMaxLength, eventTitleMaxLength, organizationProfileDefault } from "../../../../App/GlobaleVariables"
import { isDefined } from "../../../../App/Helpers/Helpers"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import CharacterCounter from "../../../Shared/Components/CharacterCounter"
import { CloseIcon, Upload } from "../../../Shared/Components/SvgIcons"
import Button from "../../../Shared/Primitive/Button"
import Image from "../../../Shared/Primitive/Image"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { getPhotoSizeErrorMessage } from "../../../Shared/SharedSlices/RoomSlice"
import { GetOrganizationRequest } from "../../OrganizationDashboard/GetOrganizationServiceRequest"
import { postEventRequest } from "./OrganizationPinboardServiceRequest"
import { EditorModifier } from "../../../../App/enums"
import { Editor as TinyMCEEditor } from "tinymce"
import EditorTextArea from "../../../Shared/Components/Editor/EditorTextArea"
import { hashtagRegex } from "../../../../App/Regex"
import { getEventDescriptionErrorState } from "../../../Shared/SharedSlices/SponsorCardSlice"

function OrganizationProfileAddEvent() {
    const { id } = useParams()
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState<any>(null)
    const [previewUrl, setPreviewUrl] = useState<string>()
    const organizationId: number = organization.id!
    const photoziseErrorState = useSelector((state: RootState) => state.roomSlice.photoSizeErrorSlice.message)
    const textWithoutHtml = description!.replace(hashtagRegex, "$1")
    const descriptionErrorState = useSelector((state: RootState) => state.sponsorSlice.EventDescriptionErrorSlice.message)

    useEffect(() => {
        if (userDataState.id) {
            GetOrganizationRequest(id, userDataState)
            dispatch(getEventDescriptionErrorState(""))
        }
    }, [dispatch, id, userDataState, userDataState.id])

    const onCloseModal = () => {
        dispatch(setModalData({ open: false, addEvent: false }))
        dispatch(getPhotoSizeErrorMessage(false))
        setTitle("")
        setDescription("")
        setImage(null)
        dispatch(getEventDescriptionErrorState(""))
    }

    const onPhotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (files) {
            const reader = new FileReader()
            reader.readAsDataURL(files[0])
            reader.addEventListener("load", () => {
                setImage(files[0])
                setPreviewUrl(URL.createObjectURL(files[0]))
            })
        }
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const id = organizationId.toString()
        const formData = new FormData()
        image !== null && formData.append("event_photo", image)
        formData.append("title", title)
        formData.append("organization", id)
        formData.append("description", textWithoutHtml)

        dispatch<any>(postEventRequest(organizationId, formData))
    }

    return (
        <>
            <form onSubmit={onFormSubmit} className="organization-profile-add-event">
                <div className="organization-profile-add-event__top-container">
                    <div className="organization-profile-add-event__organization-container">
                        <div className="organization-profile-add-event__organization-photo">
                            <Image
                                className="image image__profile image__profile--fit image__profile--size-small"
                                src={organization.photo?.url_path || organizationProfileDefault}
                                alt="organization"
                            />
                        </div>
                        <div className="organization-profile-add-event__organization-name">{organization.name}</div>
                    </div>
                    <div onClick={onCloseModal} className="organization-profile-add-event__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="organization-profile-add-event__content">
                    <div className="organization-profile-add-event__max-character-container">
                        <input
                            className="organization-profile-add-event__title-input"
                            placeholder={translation.addTitle}
                            maxLength={eventTitleMaxLength}
                            value={title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
                        />
                        <CharacterCounter current={title.length} max={eventTitleMaxLength} modifier="margin-right" />
                    </div>
                    <div className="organization-profile-add-event__max-character-container">
                        <div className="organization-profile-add-event__description-input">
                            <EditorTextArea
                                inputState={description}
                                setInputState={setDescription}
                                editorRef={editorRef}
                                editorPlaceholder={translation.addDescription}
                                editorHeight={72}
                                modifier={EditorModifier.eventDescription}
                                initialEditorValue={""}
                                setContent={() => {}}
                                enterPressed={() => {}}
                            />
                        </div>
                        <CharacterCounter current={textWithoutHtml.length} max={eventDescriptionMaxLength} modifier="margin-right" />
                    </div>
                    {descriptionErrorState && <div>{descriptionErrorState}</div>}
                    <div className="organization-profile-add-event__image-container">
                        <input className="organization-profile-add-event__input-file" type="file" onChange={onPhotoChange} accept="image/*" />
                        {!image ? (
                            <div className="organization-profile-add-event__image-placeholder">
                                <Upload />
                                <div className="organization-profile-add-event__image-text">
                                    {translation.uploadWithSpace} <br />
                                    {translation.imageWithSpace}
                                </div>
                                <p className="organization-profile-add-event__image-size-text">{translation.maxImageSize}</p>
                            </div>
                        ) : (
                            <Image className="organization-profile-add-event__image" src={previewUrl} alt="event" />
                        )}
                    </div>
                    {photoziseErrorState && <p className="crop__error-message">{translation.fileIsTooBig}</p>}
                </div>
                <div className="organization-profile-add-event__buttons_container">
                    <div onClick={onCloseModal} className="organization-profile-add-event__delete-button">
                        {translation.deleteEvent}
                    </div>
                    <div className="organization-profile-add-event__button">
                        <Button
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-gold button--hover-brighter button--cursor-pointer button--border-radius-05"
                            disabled={!isDefined(title) || !isDefined(description)}
                        >
                            {translation.save}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default OrganizationProfileAddEvent
