import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import Button from "../../Shared/Primitive/Button"
import { LogoIcon } from "../../Shared/Components/SvgIcons"
import TextInput from "../../Shared/Primitive/TextInput"
import { resetPasswordPatchRequest } from "./ResetPasswordServiceRequests"
import { selectTranslations } from "../../../App/i18n/i18nSlice"

const ResetPasswordForm = () => {
    const navigate = useNavigate()
    let dispatch = useDispatch()
    const errorState = useSelector((state: RootState) => state.resetPasswordSlice.resetPasswordError)
    const statusState = useSelector((state: RootState) => state.resetPasswordSlice.resetPasswordStatus)
    const translation = useSelector(selectTranslations)

    const { token } = useParams()
    const { uidb64 } = useParams()
    const [resetPasswordForm, setResetPasswordForm] = useState({ password: "", confirm_password: "", token: token, uidb64: uidb64 })

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResetPasswordForm({ ...resetPasswordForm, [event.target.name]: event.target.value })
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch<any>(resetPasswordPatchRequest(resetPasswordForm))
    }

    useEffect(() => {
        if (statusState.status === true) {
            navigate("/")
            window.scrollTo(0, 0)
        }
    }, [navigate, statusState])

    return (
        <form className="reset-password-form" onSubmit={onFormSubmit}>
            <div className="reset-password-form__logo">
                <LogoIcon />
            </div>
            <div className="reset-password-form__title">
                <h2> {translation.resetYourPassword} </h2>
            </div>
            <>
                <h4 className=" reset-password-form__subtitle ">
                    {translation.minimumCharacters} {translation.mustContain} <p className="h4__position">{translation.upperAndLowercaseNumbersAndSimbols}</p>
                </h4>
            </>

            <div className="reset-password-form__single-input">
                <p className="p3 reset-password-form__small-text">{translation.newPassword}</p>
                <TextInput type="password" textinputsize="large" name="password" value={resetPasswordForm.password} onChange={onInputChange} />
                <p className="p3  reset-password-form__error-message">{errorState.message.password}</p>
            </div>
            <div className="reset-password-form__single-input">
                <p className="p3 reset-password-form__small-text">{translation.confirmNewPassword}</p>
                <TextInput type="password" textinputsize="large" name="confirm_password" value={resetPasswordForm.confirm_password} onChange={onInputChange} />

                <p className="p3  reset-password-form__error-message">
                    {errorState.message.confirm_password} {errorState.message.non_field_errors}
                </p>
            </div>
            <div className="reset-password-form__button-container">
                <div className=" reset-password-form__button-size">
                    <Button
                        type="submit"
                        className="button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                    >
                        <div>{translation.changePassword}</div>
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default ResetPasswordForm
