import { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { headers } from "../../../../App/Axios"
import { organizationProfileDefault } from "../../../../App/GlobaleVariables"
import HTTPService from "../../../../App/HTTPService"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import "../../../../Styles/main.scss"
import { LoadingAnimation } from "../../../Shared/Components/SvgIcons"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { setSponsorCardData, getEventId, getEventStatus } from "../../../Shared/SharedSlices/SponsorCardSlice"
import { setPinboardId } from "../OrganizationProfilePinboardSlice"
import OrganizationProfilePinboard from "./OrganizationProfilePinboard"
import OrganizationProfilePinboardPlaceholder from "./OrganizationProfilePinboardPlaceholder"
import TutorialBubble from "../../../Shared/Components/TutorialBubble"

const getEventsRequest = (id: string) => {
    return HTTPService.get(`/organization/${id}/events/`, headers)
}

function OrganizationProfilePinboardContainer(props: { isYourOrganization: boolean; hasTitle: boolean }) {
    const dispatch = useDispatch()
    const { id, eventId } = useParams()
    const translation = useSelector(selectTranslations)
    const [isLoading, setIsLoading] = useState(true)
    const pinboardMinItems = 3
    const [pinboardPlaceholders, setPinboardPlaceholders] = useState<Array<{ id: string }>>([])
    const events = useSelector((state: RootState) => state.sponsorSlice.SponsorCardSlice.events)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const eventStatus = useSelector((state: RootState) => state.sponsorSlice.EventStatusSlice.status)

    useEffect(() => {
        if (eventId) {
            dispatch(setModalData({ open: true, pinboardCommentsModal: true }))
            dispatch(setPinboardId({ id: parseInt(eventId), isYourOrganization: props.isYourOrganization }))
        }
        dispatch(getEventStatus(true))
    }, [])

    const onPinboardPlaceholdersCall = useCallback(() => {
        const pinboardPlaceholderItems = []
        for (let index = 0; index < pinboardMinItems; index++) {
            if (!events![index]) {
                pinboardPlaceholderItems.push({ id: `pinboardPlaceholder_${index}` })
            }
        }
        setPinboardPlaceholders(pinboardPlaceholderItems)
    }, [events])

    useEffect(() => {
        onPinboardPlaceholdersCall()
    }, [onPinboardPlaceholdersCall])

    useEffect(() => {
        let isMounted = true
        if (eventStatus) {
            getEventsRequest(id!)
                .then(response => {
                    if (isMounted && response.status === 200) {
                        dispatch(setSponsorCardData(response.data.events))
                        dispatch(getEventStatus(false))
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            return () => {
                isMounted = false
                setIsLoading(false)
            }
        }
    }, [dispatch, events.length, eventStatus, id])

    const onAddEventModalOpen = (id: number) => {
        if (props.isYourOrganization) {
            dispatch(getEventId(id))
            dispatch(setModalData({ open: true, editEvent: true }))
        }
    }

    return (
        <>
            {props.hasTitle && (
                <p className="main-right-panel__pinboard-title">
                    {props.isYourOrganization && (
                        <TutorialBubble text={translation.tutorial_org_events} modifier="right speechbubble__event" visible={organization.tutorial_progress === 8} hasNext={true} />

                    )}
                    {props.isYourOrganization ? translation.pinboard : events.length === 0 ? translation.noEventsYet : translation.pinboard}
                </p>
            )}
            {isLoading ? (
                <LoadingAnimation />
            ) : (
                <div className="organization-profile-pinboard-container">
                    {events.length > 0 &&
                        events.slice(0, 3)?.map(event => {
                            return (
                                <div key={event.id}>
                                    <OrganizationProfilePinboard
                                        showEdit={props.isYourOrganization}
                                        onClick={() => onAddEventModalOpen(event.id!)}
                                        id={event.id!}
                                        title={event.title!}
                                        description={event.description!}
                                        photo={event?.event_photo!}
                                        organization_name={props.isYourOrganization ? organization?.name! : event.organization?.name!}
                                        profile_photo={
                                            (props.isYourOrganization ? organization.photo?.url_path : event.organization?.photo?.url_path) ||
                                            organizationProfileDefault
                                        }
                                        commentsCount={event.comment_count}
                                        hashtags={event.hashtags}
                                    />
                                </div>
                            )
                        })}
                    <div>
                        {props.isYourOrganization &&
                            pinboardPlaceholders.map(item => {
                                return <div key={item.id}>{<OrganizationProfilePinboardPlaceholder />}</div>
                            })}
                    </div>
                </div>
            )}
        </>
    )
}

export default OrganizationProfilePinboardContainer
