import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "../../../Styles/main.scss"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import Button from "../../Shared/Primitive/Button"
import Checkbox from "../../Shared/Primitive/Checkbox"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { deleteAccountPostRequestWithReason, deleteAccountRequest } from "./UserDeleteAccountServiceRequest"
import { mitgliedschaftEmail } from "../../../App/GlobaleVariables"
import { getUserDeleteAccountError } from "./UserDeleteAccountSlice"

function UserDeleteAccount() {
    const dispatch = useDispatch()
    const deleteUserError = useSelector((state: RootState) => state.deleteAccount)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const translation = useSelector(selectTranslations)
    const [isChecked, setIsChecked] = useState(false)
    const [textAreaValue, setTextAreaValue] = useState("")
    const [deleteAccountInfo, setDeleteAccountInfo] = useState<Record<string, boolean | undefined>>({
        has_organization: undefined,
        has_donation: undefined,
    })

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        if (textAreaValue.length > 0) {
            dispatch<any>(deleteAccountPostRequestWithReason(textAreaValue, userDataState))
        } else {
            dispatch<any>(deleteAccountRequest(userDataState))
        }
    }

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked)
    }

    const preDeleteAccountRequest = async () => {
        try {
            const response = await HTTPService.get(`/pre-delete-user/`, headers)
            if (response.status === 200) {
                setDeleteAccountInfo({
                    ...deleteAccountInfo,
                    ...response.data,
                })
            }
        } catch (error: any) {
            console.log(error)
        }
    }
    useEffect(() => {
        dispatch(
            getUserDeleteAccountError({
                message: { error: "", reason: "" },
                status: 0,
            })
        )
        preDeleteAccountRequest()
    }, [])

    return (
        <form className="delete-account" onSubmit={onFormSubmit}>
            <div className="delete-account__form">
                <p className="delete-account__title">{translation.deleteAccount}</p>
                <p className="delete-account__information-title">{translation.doYouWantToDeleteAccount}</p>
                <div className="delete-account__information-container">
                    {deleteAccountInfo.has_donation ? (
                        deleteAccountInfo.has_organization ? (
                            <>
                                <p className="delete-account__information">
                                    {translation.userDeleteAccountWithOrganization1}

                                    {translation.userDeleteAccountWithOrganization2}
                                </p>
                                <p className="delete-account__information">
                                    {translation.userDeleteAccountEduFanMember1}
                                    &nbsp;
                                    <a className="delete-account__link" href={`mailto:${mitgliedschaftEmail}`} target="_blank" rel="noreferrer">
                                        {mitgliedschaftEmail}
                                    </a>
                                    &nbsp;
                                    {translation.userDeleteAccountEduFanMember2}
                                </p>
                            </>
                        ) : (
                            <p className="delete-account__information">
                                {translation.userDeleteAccountEduFanMember1}
                                &nbsp;
                                <a className="delete-account__link" href={`mailto:${mitgliedschaftEmail}`} target="_blank" rel="noreferrer">
                                    {mitgliedschaftEmail}
                                </a>
                                {translation.userDeleteAccountEduFanMember2}
                            </p>
                        )
                    ) : deleteAccountInfo.has_organization ? (
                        <p className="delete-account__information">
                            {translation.userDeleteAccountWithOrganization1}

                            {translation.userDeleteAccountWithOrganization2}
                        </p>
                    ) : (
                        <>
                            {" "}
                            <p className="delete-account__information">{translation.deleteAccountAnytime}</p>
                            <p className="delete-account__information">
                                {translation.userDeleteAccountEduFanMember1}
                                &nbsp;
                                <a className="delete-account__link" href={`mailto:${mitgliedschaftEmail}`} target="_blank" rel="noreferrer">
                                    {mitgliedschaftEmail}
                                </a>
                                &nbsp;
                                {translation.userDeleteAccountWithoutOrganization2}
                            </p>
                        </>
                    )}
                </div>
                <div className="delete-account__text-area-container">
                    <p className="delete-account__text-area-title">{translation.deleteAccountReason}</p>
                    <textarea
                        className="delete-account__text-area"
                        value={textAreaValue}
                        onChange={event => {
                            setTextAreaValue(event.target.value)
                        }}
                    />
                </div>
                <p className="p3  delete-account__error-message">{deleteUserError.message.reason!}</p>
                <p className="p3  delete-account__error-message">{deleteUserError.message.error}</p>
                <div className="delete-account__checkbox">
                    <Checkbox color="red" checked={isChecked} onChange={onInputChange} />
                    <p className="delete-account__checkbox-text">{translation.acceptConsequences}</p>
                </div>
            </div>
            <div className="delete-account__button-container">
                <div className="delete-account__button">
                    {isChecked && !deleteAccountInfo.has_organization ? (
                        <Button className="button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 ">
                            {translation.delete}
                        </Button>
                    ) : (
                        <Button
                            disabled
                            className="button button--border-radius-05 button--color-grey  button--text-color-white button--font-size-small button--font-weight-400 "
                        >
                            {translation.delete}
                        </Button>
                    )}
                </div>
            </div>
        </form>
    )
}

export default UserDeleteAccount
