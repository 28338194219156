
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"

export const onGetGlobalSearchFilteredData = async (searchQuery: string, pageNumber:number,result_type:string,hasHashtag:boolean,stateSearch? :boolean) =>  {

  if(stateSearch){
    return HTTPService.get(`/search/?query=${searchQuery}&page=${pageNumber}&result_type=${result_type}&is_state_search=true`, headers)
  }

  if(hasHashtag){
    return HTTPService.get(`/search/?query=${searchQuery}&page=${pageNumber}&result_type=${result_type}&is_hashtag=true`, headers)
    
  } return HTTPService.get(`/search/?query=${searchQuery}&page=${pageNumber}&result_type=${result_type}`, headers)
  

}